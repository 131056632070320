import { faBullseye, faClock, faMedal } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ImageService } from "../../services";

export interface WelcomeProps { }

export const Welcome: React.FunctionComponent<WelcomeProps> = (props) => {
    return (
        <div className="welcome-component">
            <section className="bg-light pt-3">
                <div className="container">
                    <div className="row m-0">
                        <div className="col-12 col-lg-8 pt-3 mb-5 mb-lg-0">
                            <h1 className="display-3 mb-3 text-center text-md-start">
                                It's time to rank up Spartan!
                            </h1>
                            <div className="lead mb-4">
                                Spartan Rank 152 is a long grind.
                                Get estimates on how long you'll have to play, how many wins you'll need, how much damage you'll have to deal, and more until you reach max rank in Halo 5: Guardians.
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 text-center">
                            <img
                                className="d-none d-lg-block mx-auto img-fluid"
                                src={ImageService.defaultSpartanImage()}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 mx-n2">
                <div className="container">
                    <h2 className="mb-2 text-center h3 fw-bold">
                        Rank Up Easier
                    </h2>
                    <div className="mb-5 text-center section-intro">
                        Get fun metric estimates on your progression towards Spartan Rank 152.
                    </div>
                    <div className="row m-0 justify-content-center">
                        <div className="col-12 col-md-4 col-lg-3 mb-3">
                            <div className="card text-center h-100">
                                <div className="card-body">
                                    <div className="badge rounded-pill bg-light mx-auto mb-3 text-secondary p-3">
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            size="2x"
                                        />
                                    </div>
                                    <h5 className="fw-bold mb-3">Time</h5>
                                    <div>
                                        How many weeks, days, hours, and minutes you'll have to play.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mb-3">
                            <div className="card text-center h-100">
                                <div className="card-body">
                                    <div className="badge rounded-pill bg-light mx-auto mb-3 text-secondary p-3">
                                        <FontAwesomeIcon
                                            icon={faMedal}
                                            size="2x"
                                        />
                                    </div>
                                    <h5 className="fw-bold mb-3">Wins</h5>
                                    <div>
                                        How many remaining games you'll have to play, and how many wins and losses you'll likely see.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mb-3">
                            <div className="card text-center h-100">
                                <div className="card-body">
                                    <div className="badge rounded-pill bg-light mx-auto mb-3 text-secondary p-3">
                                        <FontAwesomeIcon
                                            icon={faBullseye}
                                            size="2x"
                                        />
                                    </div>
                                    <h5 className="fw-bold mb-3">Kills</h5>
                                    <div>
                                        How many kills, deaths, and assists and more until SR152.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-3 d-none d-lg-block text-center">
                            <img
                                className="img-thumbnail img-fluid py-3"
                                title="Spartan Rank 152 Inheritor Pack"
                                src="https://image.halocdn.com/h5/requisition-packs/bbc7bfd1-c51f-4653-aacc-ada9e9a16100?locale=en&width=200&hash=BbmL%2b53L%2bhOgW4CjM124MOWTmFGzpG5%2fIuMFk%2fwWzJY%3d"
                            />
                        </div>
                        <div className="col">
                            <h2 className="">
                                Carry your prestige into Halo Infinite
                            </h2>
                            <p>
                                While reaching Spartan Rank 152 rewards you with the Inheritor Requisition Pack and the exclusive Mantle emblem in Halo 5,
                                your prestige can carry forward to Halo Infinite with the Watchdog armor and weapon coatings, exclusive to players who
                                reach Spartan Rank 152 in Halo 5: Guardians!
                            </p>
                            <img
                                className="img-fluid"
                                title="Halo Infinite Watchdog Armor and Weapon Coating"
                                src="/images/watchdog-armor.jpg"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Welcome;
