/** Information about medals earned by a player. */
export class MedalProgress {
    id: number;
    name: string;
    description: string;
    currentCount: number;
    maxLevelRemainingCount: number;

    constructor();
    constructor(medalProgress: Partial<MedalProgress>);
    constructor(data?: Partial<MedalProgress>) {
        data = data || {};

        this.id = data.id || 0;
        this.name = data.name || null;
        this.description = data.description || null;
        this.currentCount = data.currentCount || 0;
        this.maxLevelRemainingCount = data.maxLevelRemainingCount || 0;
    }
}

export default MedalProgress;
