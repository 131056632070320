import React from "react";
import { Button, Modal } from "react-bootstrap";
import config from "../../../../config";
import { MedalProgress } from "../../../../models";

export interface MedalModalProps {
    medal: MedalProgress;
    showModal: boolean;
    closeModal: () => void;
}

export const MedalModal: React.FunctionComponent<MedalModalProps> = (props) => {
    return (
        <Modal show={props.showModal} onHide={() => props.closeModal()} style={{ marginTop: "100px"}}>
            <Modal.Header>
                <div className="w-100 text-center">
                    <img
                        src={`${config.apiRootUrl}/api/images/medals/${props.medal.id}`}
                        title={props.medal.name}
                        className="d-block mx-auto"
                    />
                    <h2 className="h3 d-inline-block align-middle ms-3">
                        { props.medal.name }
                    </h2>
                    <p>
                        { props.medal.description }
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <b className="d-inline-block me-2">
                        Current:
                    </b>
                    { props.medal.currentCount }
                </p>
                <p>
                    <b className="d-inline-block me-2">
                        Remaining:
                    </b>
                    { props.medal.maxLevelRemainingCount }
                </p>
                <p>
                    <b className="d-inline-block me-2">
                        Estimated Total:
                    </b>
                    { props.medal.currentCount + props.medal.maxLevelRemainingCount }
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="ms-auto"
                    onClick={e => props.closeModal()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MedalModal;
