import React from "react";

export interface AboutProps { }

export const About: React.FunctionComponent = (props) => {
    return (
        <div className="container mt-3">
            <h1 className="h2">About</h1>
            <hr />
            <p className="lead">
                SR152 is an app which shows estimated progression data towards player's progress to Spartan Rank 152 in Halo: 5 Guardians.
            </p>

            <h2 className="h3 mt-5">Game Progression</h2>
            <hr />
            <p>
                Halo 5: Guardians features a progression system where players earn experience points (XP) which contributes to their total Spartan Rank.
            </p>
            <p>
                The player begins the game at Spartan Rank 1 and can progress to the maximum rank of Spartan Rank 152 which requires 50,000,000 XP.
            </p>
            <p>
                Players gain experience points by competing in competitive match-made Arena or Warzone games or cooperative Warzone Firefight missions.
            </p>
            <p>
                The amount of XP required for each Spartan Rank increases between each rank, with the XP required for the final levels increasing drastically.
            </p>
            <p>
                Spartan Rank 125 is roughly 5% of the total XP required to reach Spartan Rank 152 at 4,935,000 XP.
                Spartan Rank 150 is just under 50% of the total XP required at 24,000,000 XP.
            </p>

            <h2 className="h3 mt-5">App Purpose</h2>
            <hr />
            <p>
                This application provides linear progress estimates regarding games played, games won, kills, shots fired, and more until a player reaches Spartan Rank 152 based on past match data.
            </p>
            <p>
                These estimates are aimed at making progression a little more fun while showcasing the extreme requirements of Spartan Rank 152.
            </p>
        </div>
    );
}

export default About;
