export class MapVariant {
    name: string;
    description: string | null;
    image: string;

    constructor()
    constructor(mapVariant: Partial<MapVariant>)
    constructor(data?: Partial<MapVariant>) {
        data = data || {};

        this.name = data.name || null;
        this.description = data.description || null;
        this.image = data.image || null;
    }
}

export default MapVariant;
