import "./PlayerStat.scss";
import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";

export interface PlayerStatProps {
    icon: IconDefinition;
    statName: string;
    isLargeStat?: boolean;
    currentLabel?: string;
    currentValue: string;
    remainingLabel?: string;
    remainingValue: string;
    totalLabel?: string;
    totalValue: string;
    showDetails: () => void;
}

export const PlayerStat: React.FunctionComponent<PlayerStatProps> = (props) => {
    const details = `${props.currentLabel || `Current ${props.statName}`}: ${props.currentValue || ""}\r\n`
        + `${props.remainingLabel || `Est. Remaining ${props.statName}`}: ${props.remainingValue || ""}\r\n`
        + `${props.totalLabel || `Est. Total ${props.statName}`}: ${props.totalValue || ""}`;

    return (
        <div className="player-stat-component">
            <div className="row">
                <div className="col-6 col-sm-4 col-md-4">
                    <h3 className="h4 d-inline-block align-middle text-truncate">
                        <div className="icon me-2">
                            <FontAwesomeIcon icon={props.icon} title={props.statName} />
                        </div>
                        { props.statName}
                    </h3>
                </div>
                <div className="col text-center text-sm-end">
                    <span className="d-none d-sm-inline-block">
                        { props.currentValue } / { props.totalValue }
                    </span>
                    <button className="btn btn-sm btn-outline-secondary mt-1 mt-sm-0 ms-auto ms-sm-2 d-block d-sm-inline-block" onClick={e => props.showDetails()}>
                        <span className="d-none d-md-inline-block me-1">Details</span>
                        <FontAwesomeIcon icon={faInfoCircle}
                            title={details}
                            className="text-primary"
                        />
                    </button>
                </div>
            </div>
            <div className={`row g-0 d-sm-none text-truncate ${props.isLargeStat ? "text-center" : ""}`}>
                <div className={!props.isLargeStat ? "col" : "col-12"}>
                    <b>Current:</b> { props.currentValue }
                </div>
                <div className={!props.isLargeStat ? "col text-end" : "col-12"}>
                    <b>Remaining:</b> { props.remainingValue }
                </div>
            </div>
            <hr />
        </div>
    );
}

export default PlayerStat;
