import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageInfo } from "../../models";
import { storageUtility } from "../../utilities";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import "./Layout.scss";
import Navigation from "./Navigation/Navigation";
import ToastContainer from "./ToastContainer/ToastContainer";
import Tracking from "./Tracking/Tracking";

type Visits = {
    [key: string]: boolean;
}

/** Configuration options for usePageLayout. */
export interface PageLayoutOptions {

    /** Information about the current page. */
    pageInfo: PageInfo;

    /** An optional flag which when true disables the default padding in the layout's body. */
    noPadding?: boolean;
}

/** A HOC which renders the page layout around any component. */
export const usePageLayout = (Component: React.JSXElementConstructor<any>, options: PageLayoutOptions): React.ReactElement => {
    const Layout: React.FunctionComponent = (props) => {
        const [promptForFeedback, setPromptForFeedback] = useState<boolean>(false);
        const history = useHistory();
        const navigate = (e: React.UIEvent, path: string) => {
            e.preventDefault();

            history.push(path);
        };
        const visitsKey = "layout.visits";
        const dismissFeedbackKey = "layout.dismiss-feedback";
        const addVisit = (): void => {
            const visits = storageUtility.getFromLocalStorage<Visits>(visitsKey) || {};
            visits[new Date().toDateString()] = true;

            storageUtility.saveToLocalStorage(visitsKey, visits);
        };
        const dismissFeedback = () => {
            storageUtility.saveToLocalStorage(dismissFeedbackKey, 1);
            setPromptForFeedback(false);
        }
        const showFeedback = () => {
            dismissFeedback();
            history.push("/feedback");
        };

        useEffect(() => {
            addVisit();

            history.listen(() => window.scrollTo(0, 1));

            // show feedback prompt?
            const visits = storageUtility.getFromLocalStorage<Visits>(visitsKey) || {};
            const dismissed = storageUtility.getFromLocalStorage(dismissFeedbackKey);
            if (!dismissed && Object.keys(visits).length > 3 && Component.name !== "Feedback") {
                setPromptForFeedback(true);
            }
        }, []);

        return (
            <div className="layout-component">
                <Tracking
                    pageInfo={options.pageInfo}
                />
                <header>
                    <nav>
                        <Navigation />
                    </nav>
                </header>
                <main role="main">
                    <ToastContainer />
                    <FeedbackModal
                        show={promptForFeedback}
                        showFeedback={() => showFeedback()}
                        dismiss={() => dismissFeedback()}
                    />
                    <div className={`container-fluid ${options.noPadding ? "px-0" : ""}`}>
                        <div className="row m-0 h-100">
                            { /*
                            <div className="col-3 col-xl-2 d-none d-lg-block" style={{ borderRight: "1px solid black" }}>
                                Sidebar
                            </div>
                            */ }
                            <div className={`col ${options.noPadding ? "px-0" : "pt-3 px-2 px-sm-4"}`}>
                                <Component />
                            </div>
                        </div>
                    </div>
                </main>
                <footer className="bg-light border-top footer small text-muted">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg text-center text-md-left">
                                  &copy; { new Date().getFullYear() === 2021 ? "2021" : `2021 - ${new Date().getFullYear()}` } Lod Softworks LLC, All Rights Reserved
                            </div>
                            <div className="col col-lg text-center text-md-right">
                                <a href="/about" onClick={e => navigate(e, "/about")} className="text-muted me-2">
                                    About
                                </a>
                                <a href="/feedback" onClick={e => navigate(e, "/feedback")} className="text-muted me-2">
                                    Feedback
                                </a>
                                <a href="/terms-and-conditions" onClick={e => navigate(e, "/terms-and-conditions")} className="text-muted me-2">
                                    Terms &amp; Conditions
                                </a>
                                <a href="/legal" onClick={e => navigate(e, "/legal")} className="text-muted me-2">
                                    Legal Notices
                                </a>
                                <a href="https://www.lodsoftworks.com/privacy" className="text-muted" target="_blank" rel="noreferrer">
                                    <u>Privacy</u>
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }

    return (<Layout />);
};

export default usePageLayout;
