import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Match, Rank, Spartan } from "../../models";
import { MatchService, RankService, SpartanService, ToastService } from "../../services";
import Loading from "../Loading/Loading";
import Player from "./Player/Player";
import PlayerNotFound from "./PlayerNotFound/PlayerNotFound";

export interface SpartanProps { }

export const App: React.FunctionComponent<SpartanProps> = (props) => {
    const { gamertag } = useParams<{ gamertag: string; }>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ranks, setRanks] = useState<Rank[]>();
    const [spartan, setSpartan] = useState<Spartan>();
    const [latestMatch, setLatestMatch] = useState<Match>();

    useEffect(() => {
        const latestMatch = Promise.resolve()
            .then(() => gamertag ? MatchService.getLatestMatch(gamertag) : null)
            .then(match => setLatestMatch(match))
            .catch(error => ToastService.toast("danger", "An error occured retrieving previous match data from the server.", "Match Lookup Error", 10000))

        Promise.resolve()
          .then(() => setIsLoading(true))
          .then(() => {
              setSpartan(null);
              setLatestMatch(null);
          })
          .then(() => !ranks ? RankService.getAll() : null)
          .then(ranks => ranks ? setRanks(ranks) : null)
          .then(() => gamertag ? SpartanService.getSpartan(gamertag) : null)
          .then(spartan => setSpartan(spartan))
          .then(() => latestMatch)
          .catch(error => ToastService.toast("danger", "An error occured retrieving spartan information from the server.", "Spartan Lookup Error", 10000))
          .finally(() => setIsLoading(false));
    }, [gamertag]);

    let component: React.ReactElement;
    if (isLoading) {
        component = (<Loading message="Retrieving Spartan Status" />);
    } else if (spartan) {
        component = (
            <Player
                ranks={ranks}
                spartan={spartan}
                latestMatch={latestMatch}
            />
        );
    } else {
        component = (
            <PlayerNotFound
                gamertag={gamertag}
            />
        );
    }

    return (
        <div className="spartan-component">
            { component }
        </div>
    );
}

export default App;
