import config from "../config";
import Feedback from "../models/Feedback";

/** A service which exposes methods for working with user feedback. */
export const FeedbackService = {

    /** Returns a promise of a new user feedback. */
    save: (feedback: Feedback): Promise<void> => fetch(`${config.apiRootUrl}/api/feedback`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(feedback),
    }).then(r => {
        if (!r.ok) {
            throw r;
        }
    }),
}

export default FeedbackService;
