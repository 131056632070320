import "./Toast.scss";
import React from "react";
import { Toast as BootstrapToast } from "react-bootstrap";

export interface ToastProps {

    /** The classification of the toast. */
    type: "primary" | "secondary" | "success" | "info" | "warning" | "danger";

    /** The title of the toast. */
    title: string;

    /** The body content of the toast. */
    message: string;

    /** The optional number of milliseconds to auto-hide the toast after.  */
    hideAfter?: number;

    /** A call back to the parent component indicating the toast should be closed. */
    onClose: () => void;
}

export const Toast: React.FunctionComponent<ToastProps> = (props) => {
    return (
        <BootstrapToast
            className="toast-component"
            style={{
                marginBottom: "10px",
            }}
            autohide={props.hideAfter && props.hideAfter > 0}
            delay={props?.hideAfter}
            onClose={() => props.onClose()}
        >
            <BootstrapToast.Header>
                <i className={`rounded me-2 d-inline-block bg-${props.type}`} style={{ width: "1rem", height: "1rem", }} />
                <strong className="me-auto">{props.title || ""}</strong>
                {/*<small>just now</small>*/}
            </BootstrapToast.Header>
            <BootstrapToast.Body>
                {props.message}
            </BootstrapToast.Body>
        </BootstrapToast>
    )
}

export default Toast;
