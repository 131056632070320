import config from "../config";

/** A utility which exposes methods for working with the browser page and document. */
export const pageUtility = {

    /** Updates the title of the page. */
    titlePage: (title?: string): void => {
        if (typeof document !== "undefined") {
            document.title = title ? `${title} | ${config.defaultPageTitle}` : config.defaultPageTitle;
        }
    }
}

export default pageUtility;
