/** Information about a page within the site. */
export class PageInfo {
    name: string;
    url: string;
    path: string;
    title: string;
    description: string;

    constructor();
    constructor(pageInfo: Partial<PageInfo>);
    constructor(data?: Partial<PageInfo>) {
        data = data || {};

        this.name = data.name || null;
        this.url = data.url || null;
        this.path = data.path || null;
        this.title = data.title || null;
        this.description = data.description || null;
    }
}

export default PageInfo;
