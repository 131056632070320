import { FunctionComponent, ReactElement, useState } from "react";
import { Feedback as FeedbackModel } from "../../models";
import { FeedbackService, ToastService } from "../../services";
import { analytics } from "../../utilities";
import FeedbackCheckbox from "./FeedbackCheckbox/FeedbackCheckbox";

let fieldNum: number = 1;

export interface IFeedbackProps { }

export const Feedback: FunctionComponent<IFeedbackProps> = (props): ReactElement => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const [feedback, setFeedback] = useState<FeedbackModel>(new FeedbackModel());
    const [userName, setUserName] = useState<string>();
    const [likesAdditionalComments, setLikesAdditionalComments] = useState<string>();
    const [needsImprovementAdditionalComments, setNeedsImprovementAdditionalComments] = useState<string>();
    const [featureRequest, setFeatureRequest] = useState<string>();
    const [gamertag, setGamertag] = useState<string>();
    const [otherComments, setOtherComments] = useState<string>();
    const submit = () => {
        feedback.userName = userName;
        feedback.likesAdditionalComment = likesAdditionalComments;
        feedback.needsImprovementAdditionalComment = needsImprovementAdditionalComments;
        feedback.featureRequestComment = featureRequest;
        feedback.gamertag = gamertag;
        feedback.generalComment = otherComments;

        Promise.resolve()
            .then(() => setIsSubmitting(true))
            .then(() => analytics.trackEvent("select_item", {
                "item_list_name": "Submit Feedback",
                items: ["Submit Feedback"]
            }))
            .then(() => FeedbackService.save(feedback))
            .then(() => setFeedback(null))
            .then(() => {
                setFeedback(new FeedbackModel());
                setLikesAdditionalComments("");
                setNeedsImprovementAdditionalComments("");
                setFeatureRequest("");
                setGamertag("");
                setOtherComments("");
            })
            .then(() => window.scrollTo(0, 1))
            .then(() => ToastService.toast("success", "Thank you for your feedback!", "Feedback Submitted", 5000))
            .catch(err => ToastService.toast("danger", "An error occured saving your feedback. Please try again or contact us via email.", "Feedback Error", 10000))
            .finally(() => setIsSubmitting(false))
    }
    fieldNum += 1;

    return feedback ? (
        <div className="feedback-component container mt-3">
            <h1 className="h2">Feedback</h1>
            <hr />
            <p className="lead">
                Thank you for using SR152!
                <br />
                We'd love to hear your feedback on the app and get input on future features and improvements.
            </p>

            <div className="d-none">
                <label htmlFor={`fb-username-${fieldNum}`} className="form-label">Username</label>
                <input
                    type="text"
                    id={`fb-username-${fieldNum}`}
                    className="form-control"
                    maxLength={2000}
                    value={userName || ""}
                    onChange={e => setUserName(e.target.value)}
                />
            </div>

            <h2 className="h3 mt-5">What features do you like:</h2>
            <hr />
            <FeedbackCheckbox
                fieldName="fb-likes-progress-bar"
                label="Total XP Progression Bar"
                isLike={true}
                setValue={v => feedback.likesProgressionBar = v}
                setComment={c => feedback.likesProgressionBarComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-likes-last-match"
                label="Previous Match"
                isLike={true}
                setValue={v => feedback.likesLastMatch = v}
                setComment={c => feedback.likesLastMatchComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-likes-stat-projection"
                label="Stats Projection"
                isLike={true}
                setValue={v => feedback.likesStatProjection = v}
                setComment={c => feedback.likesStatProjectionComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-likes-recently-searched"
                label="Recently Search Players"
                isLike={true}
                setValue={v => feedback.likesRecentlySearched = v}
                setComment={c => feedback.likesRecentlySearchedComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-likes-ranks-page"
                label="Ranks Page"
                isLike={true}
                setValue={v => feedback.likesRankPage = v}
                setComment={c => feedback.likesRankPageComment = c}
            />
            <div className="mb-3">
                <label htmlFor={`fb-likes-additional-comments-${fieldNum}`}>
                    Let us know about anything else you like about the app:
                </label>
                <textarea
                    id={`fb-likes-additional-comments-${fieldNum}`}
                    className="form-control"
                    rows={3}
                    maxLength={2000}
                    value={likesAdditionalComments || ""}
                    onChange={e => setLikesAdditionalComments(e.target.value)}
                />
            </div>

            <h2 className="h3 mt-5">What features need improvement:</h2>
            <hr />
            <FeedbackCheckbox
                fieldName="fb-needs-improvement-progress-bar"
                label="Total XP Progression Bar"
                isLike={false}
                setValue={v => feedback.needsImprovementProgressionBar = v}
                setComment={c => feedback.needsImprovementProgressionBarComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-needs-improvement-last-match"
                label="Previous Match"
                isLike={false}
                setValue={v => feedback.needsImprovementLastMatch = v}
                setComment={c => feedback.needsImprovementLastMatchComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-needs-improvement-stat-projection"
                label="Stats Projection"
                isLike={false}
                setValue={v => feedback.needsImprovementStatProjection = v}
                setComment={c => feedback.needsImprovementStatProjectionComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-needs-improvement-recently-searched"
                label="Recently Search Players"
                isLike={false}
                setValue={v => feedback.needsImprovementRecentlySearched = v}
                setComment={c => feedback.needsImprovementRecentlySearchedComment = c}
            />
            <FeedbackCheckbox
                fieldName="fb-needs-improvement-ranks-page"
                label="Ranks Page"
                isLike={false}
                setValue={v => feedback.needsImprovementRankPage = v}
                setComment={c => feedback.needsImprovementRankPageComment = c}
            />
            <div className="mb-3">
                <label htmlFor={`fb-needs-improvement-additional-comments-${fieldNum}`}>
                    Let us know about anything other parts of the app that could use improvement:
                </label>
                <textarea
                    id={`fb-needs-improvement-additional-comments-${fieldNum}`}
                    className="form-control"
                    rows={3}
                    maxLength={2000}
                    value={needsImprovementAdditionalComments || ""}
                    onChange={e => setNeedsImprovementAdditionalComments(e.target.value)}
                />
            </div>

            <h2 className="h3 mt-5">Let us know what features you'd like to see added:</h2>
            <hr />
            <FeedbackCheckbox
                fieldName="fb-features-mobile-app"
                label="A mobile app (Android &amp; iOS)"
                setValue={v => feedback.featureRequestMobileApp = v}
            />
            <FeedbackCheckbox
                fieldName="fb-features-prediction-tool"
                label="A prediction tool where you can adjust your Arena vs Warzone game count and XP booster use."
                setValue={v => feedback.featureRequestPredictionTool = v}
            />
            <FeedbackCheckbox
                fieldName="fb-features-xp-breakdown"
                label="A new informational page explaining XP breakdowns for matches, kills, and XP boosters."
                setValue={v => feedback.featureRequestXpBreakdownPage = v}
            />
            <div className="mb-3">
                <label htmlFor={`fb-feature-comment-${fieldNum}`}>
                    What features would you like added:
                </label>
                <textarea
                    id={`fb-feature-comment-${fieldNum}`}
                    className="form-control"
                    rows={3}
                    maxLength={2000}
                    value={featureRequest || ""}
                    onChange={e => setFeatureRequest(e.target.value)}
                />
            </div>

            <h2 className="h3 mt-5">Tell use a little more:</h2>
            <hr />
            <FeedbackCheckbox
                fieldName="fb-other-uses-on-desktop"
                label="Do you use the app on a desktop or laptop computer?"
                setValue={v => feedback.usesDesktop = v}
            />
            <FeedbackCheckbox
                fieldName="fb-other-uses-on-mobile"
                label="Do you use the app on a mobile phone?"
                setValue={v => feedback.usesMobile = v}
            />
            <div className="mb-3">
                <label htmlFor={`fb-gamertag-${fieldNum}`}>
                    Let us know what your Gamertag is! <small>(Only if you want to of course)</small>
                </label>
                <input
                    type="text"
                    id={`fb-gamertag-${fieldNum}`}
                    className="form-control"
                    maxLength={2000}
                    value={gamertag || ""}
                    onChange={e => setGamertag(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor={`fb-other-additional-comments-${fieldNum}`}>
                    Feel free to leave any other comments about the app:
                </label>
                <textarea
                    id={`fb-other-additional-comments-${fieldNum}`}
                    className="form-control"
                    rows={3}
                    maxLength={2000}
                    value={otherComments || ""}
                    onChange={e => setOtherComments(e.target.value)}
                />
            </div>

            <div className="d-grid mb-3">
                <button
                    type="button"
                    className="btn btn-lg btn-primary"
                    onClick={() => submit()}
                    disabled={isSubmitting || false}
                    >
                    {isSubmitting ? "Sending Feedback" : "Send Feedback"}
                </button>
            </div>
        </div>
    ) : null;
};

export default Feedback;
