import config from "../config";
import Spartan from "../models/Spartan";

/** A service which exposes methods for retrieving Spartan data. */
export const SpartanService = {

    /**
     * Returns a promise of Spartan data for the specified gamertag.
     * @param {string} gamertag The XBL gamertag of the Spartan to lookup.
     */
    getSpartan: (gamertag: string): Promise<Spartan> => fetch(`${config.apiRootUrl}/api/spartans/${encodeURIComponent(gamertag)}`)
        .then(resp => resp.status !== 204 ? resp.json() : null)
        .then((spartan: Spartan) => spartan ? new Spartan(spartan) : null),
}

export default SpartanService;
