import { dateUtility } from "../utilities";

/** User feedback regarding the application. */
export class Feedback {
    userName: string;
    likesProgressionBar: boolean;
    likesProgressionBarComment: string;
    likesLastMatch: boolean;
    likesLastMatchComment: string;
    likesStatProjection: boolean;
    likesStatProjectionComment: string;
    likesRecentlySearched: boolean;
    likesRecentlySearchedComment: string;
    likesRankPage: boolean;
    likesRankPageComment: string;
    likesAdditionalComment: string;

    needsImprovementProgressionBar: boolean;
    needsImprovementProgressionBarComment: string;
    needsImprovementLastMatch: boolean;
    needsImprovementLastMatchComment: string;
    needsImprovementStatProjection: boolean;
    needsImprovementStatProjectionComment: string;
    needsImprovementRecentlySearched: boolean;
    needsImprovementRecentlySearchedComment: string;
    needsImprovementRankPage: boolean;
    needsImprovementRankPageComment: string;
    needsImprovementAdditionalComment: string;

    featureRequestMobileApp: boolean;
    featureRequestPredictionTool: boolean;
    featureRequestXpBreakdownPage: boolean;
    featureRequestComment: string;

    usesMobile: boolean;
    usesDesktop: boolean;
    generalComment: string;
    gamertag: string;
    date: Date;

    constructor();
    constructor(feedback: Partial<Feedback>);
    constructor(data?: Partial<Feedback>) {
        data = data || {};

        this.userName = this.userName || null;
        this.likesProgressionBar = data.likesProgressionBar || null;
        this.likesProgressionBarComment = data.likesProgressionBarComment || null;
        this.likesLastMatch = data.likesLastMatch || null;
        this.likesLastMatchComment = data.likesLastMatchComment || null;
        this.likesStatProjection = data.likesStatProjection || null;
        this.likesStatProjectionComment = data.likesStatProjectionComment || null;
        this.likesRecentlySearched = data.likesRecentlySearched || null;
        this.likesRecentlySearchedComment = data.likesRecentlySearchedComment || null;
        this.likesRankPage = data.likesRankPage || null;
        this.likesRankPageComment = data.likesRankPageComment || null;
        this.likesAdditionalComment = data.likesAdditionalComment || null;
        this.needsImprovementProgressionBar = data.needsImprovementProgressionBar || null;
        this.needsImprovementProgressionBarComment = data.needsImprovementProgressionBarComment || null;
        this.needsImprovementLastMatch = data.needsImprovementLastMatch || null;
        this.needsImprovementLastMatchComment = data.needsImprovementLastMatchComment || null;
        this.needsImprovementStatProjection = data.needsImprovementStatProjection || null;
        this.needsImprovementStatProjectionComment = data.needsImprovementStatProjectionComment || null;
        this.needsImprovementRecentlySearched = data.needsImprovementRecentlySearched || null;
        this.needsImprovementRecentlySearchedComment = data.needsImprovementRecentlySearchedComment || null;
        this.needsImprovementRankPage = data.needsImprovementRankPage || null;
        this.needsImprovementRankPageComment = data.needsImprovementRankPageComment || null;
        this.needsImprovementAdditionalComment = data.needsImprovementAdditionalComment || null;
        this.featureRequestMobileApp = data.featureRequestMobileApp || null;
        this.featureRequestPredictionTool = data.featureRequestPredictionTool || null;
        this.featureRequestXpBreakdownPage = data.featureRequestXpBreakdownPage || null;
        this.featureRequestComment = data.featureRequestComment || null;
        this.usesMobile = data.usesMobile || null;
        this.usesDesktop = data.usesDesktop || null;
        this.generalComment = data.generalComment || null;
        this.gamertag = data.gamertag || null;
        this.date = dateUtility.parseDate(data.date) || null;
    }
}

export default Feedback;
