import { dateUtility } from "../utilities";
import MedalProgress from "./MedalProgress";

/** Information representing a service record of a Spartan. */
export class ServiceRecord {
    currentTimePlayed: Date;
    currentGamesPlayed: number;
    currentGamesWon: number;
    currentGamesLost: number;
    currentTotalKills: number;
    currentSpartanKills: number;
    currentPveKills: number;
    currentDeaths: number;
    currentShotsFired: number;
    currentShotsLanded: number;
    currentVehiclesDestroyed: number;
    currentGrenadeKills: number;
    currentMeleeKills: number;
    currentAssassinations: number;
    currentShoulderBashKills: number;
    currentGroundPoundKills: number;
    maxRankTimeRemaining: Date;
    maxRankGamesRemaining: number;
    maxRankGamesWonRemaining: number;
    maxRankGamesLostRemaining: number;
    maxRankTotalKillsRemaining: number;
    maxRankSpartanKillsRemaining: number;
    maxRankPveKillsRemaining: number;
    maxRankDeathsRemaining: number;
    maxRankShotsFiredRemaining: number;
    maxRankShotsLandedRemaining: number;
    maxRankVehiclesDestroyedRemaining: number;
    maxRankGrenadeKillsRemaining: number;
    maxRankMeleeKillsRemaining: number;
    maxRankAssassinationsRemaining: number;
    maxRankShoulderBashKillsRemaining: number;
    maxRankGroundPoundKillsRemaining: number;
    medalProgress: MedalProgress[];

    constructor()
    constructor(serviceRecord: Partial<ServiceRecord>)
    constructor(data?: Partial<ServiceRecord>) {
        data = data || {};

        this.currentTimePlayed = dateUtility.parseDate(data.currentTimePlayed);
        this.currentGamesPlayed = data.currentGamesPlayed || 0;
        this.currentGamesWon = data.currentGamesWon || 0;
        this.currentGamesLost = data.currentGamesLost || 0;
        this.currentTotalKills = data.currentTotalKills || 0;
        this.currentSpartanKills = data.currentSpartanKills || 0;
        this.currentPveKills = data.currentPveKills || 0;
        this.currentDeaths = data.currentDeaths || 0;
        this.currentShotsFired = data.currentShotsFired || 0;
        this.currentShotsLanded = data.currentShotsLanded || 0;
        this.currentVehiclesDestroyed = data.currentVehiclesDestroyed || 0;
        this.currentGrenadeKills = data.currentGrenadeKills || 0;
        this.currentMeleeKills = data.currentMeleeKills || 0;
        this.currentAssassinations = data.currentAssassinations || 0;
        this.currentShoulderBashKills = data.currentShoulderBashKills || 0;
        this.currentGroundPoundKills = data.currentGroundPoundKills || 0;
        this.maxRankTimeRemaining = dateUtility.parseDate(data.maxRankTimeRemaining);
        this.maxRankGamesRemaining = data.maxRankGamesRemaining || 0;
        this.maxRankGamesWonRemaining = data.maxRankGamesWonRemaining || 0;
        this.maxRankGamesLostRemaining = data.maxRankGamesLostRemaining || 0;
        this.maxRankTotalKillsRemaining = data.maxRankTotalKillsRemaining || 0;
        this.maxRankSpartanKillsRemaining = data.maxRankSpartanKillsRemaining || 0;
        this.maxRankPveKillsRemaining = data.maxRankPveKillsRemaining || 0;
        this.maxRankDeathsRemaining = data.maxRankDeathsRemaining || 0;
        this.maxRankShotsFiredRemaining = data.maxRankShotsFiredRemaining || 0;
        this.maxRankShotsLandedRemaining = data.maxRankShotsLandedRemaining || 0;
        this.maxRankVehiclesDestroyedRemaining = data.maxRankVehiclesDestroyedRemaining || 0;
        this.maxRankGrenadeKillsRemaining = data.maxRankGrenadeKillsRemaining || 0;
        this.maxRankMeleeKillsRemaining = data.maxRankMeleeKillsRemaining || 0;
        this.maxRankAssassinationsRemaining = data.maxRankAssassinationsRemaining || 0;
        this.maxRankShoulderBashKillsRemaining = data.maxRankShoulderBashKillsRemaining || 0;
        this.maxRankGroundPoundKillsRemaining = data.maxRankGroundPoundKillsRemaining || 0;
        this.medalProgress = (data.medalProgress || []).map(mp => new MedalProgress(mp));
    }
}

export default ServiceRecord;
