import { PlayerSearch } from "../models";

interface SearchHistoryDef {
    /** Returns all existing search history records. */
    getAll: () => PlayerSearch[];

    /** Subscribe to searches. */
    subscribe: (callback: (player: PlayerSearch) => void) => void;

    /** Publishes a new gamertag to the search history. */
    publish: (gamertag: string, serviceTag?: string) => void;
}

const service = {
    legacyLocalStorageKey: "gamertag-search-history",
    localStorageKey: "gamertag-serach-history-v2",
    localStorageLimit: 15,
    subscriptions: Array<(player: PlayerSearch) => void>(0),

    getAll: (): PlayerSearch[] => {
        let players: PlayerSearch[] = [];

        const json: string = localStorage.getItem(service.localStorageKey);
        if (json) {
            players = JSON.parse(json) || [];
        } else {
            const legacy: string = localStorage.getItem(service.legacyLocalStorageKey);
            const history: string[] = legacy ? JSON.parse(legacy) || [] : [];

            players = history.map(p => new PlayerSearch({ gamertag: p, isLegacy: true, }))
        }

        return players;
    },

    add: (gamertag: string, serviceTag?: string, emblemBase64?: string): void => {
        if (gamertag) {
            const player: PlayerSearch = new PlayerSearch({ gamertag, serviceTag, emblemBase64, isLegacy: !serviceTag, });
            const json: string = localStorage.getItem(service.localStorageKey);
            const players: PlayerSearch[] = json ? JSON.parse(json) || [] : [];
            let history = players.filter(p => p?.gamertag?.toLowerCase() !== gamertag.toLowerCase()).filter((_h, idx) => idx < service.localStorageLimit - 1);
            history = [player].concat(history);

            localStorage.setItem(service.localStorageKey, JSON.stringify(history));
        }
    },

    subscribe: (callback: (player: PlayerSearch) => void): void => {
        if (callback) {
            service.subscriptions.push(callback);
        }
    },

    publish: (gamertag: string, serviceTag?: string, emblemBase64?: string): void => {
        if (gamertag) {
            service.add(gamertag, serviceTag, emblemBase64);

            for (let sub of service.subscriptions) {
                try {
                    if (sub) {
                        sub({ gamertag, serviceTag, emblemBase64, isLegacy: false, });
                    }
                } catch (err) {
                    // ignore
                }
            }
        }
    },
};

export const SearchHistoryService = service as SearchHistoryDef;

export default SearchHistoryService;
