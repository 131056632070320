import React, { useEffect, useState } from "react";
import { ToastService } from "../../../services";
import { Toast, ToastProps } from "./Toast/Toast";

type ToastInfo = Omit<ToastProps, "onClose">;
type Toasts = {
    [index: string]: ToastInfo;
}

export interface ToastContainerProps { }

export const ToastContainer: React.FunctionComponent<ToastContainerProps> = (props) => {
    const [subToast, setSubToast] = useState<ToastInfo>();
    const [toasts, setToasts] = useState<Toasts>({});
    const closeToast = (index: string) => {
        const newToasts = { ...toasts };
        delete newToasts[index];
        setToasts(newToasts);
    };

    useEffect(() => {
        ToastService.subscribe(t => setSubToast(t));
    }, [])

    useEffect(() => {
        if (subToast) {
            const indexes = toasts ? Object.keys(toasts) : [];
            const newIdx = indexes.length ? parseInt(indexes[indexes.length - 1]) + 1 : 0;
            const newToasts = { ...toasts, }
            newToasts[newIdx] = subToast;
            setToasts(newToasts);
        }
    }, [subToast])

    return (
            <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: "absolute",
                    top: "100px",
                    right: "20px",
                }}
            >
                {Object.keys(toasts).map(key => (
                    <Toast key={key} {...toasts[key]} onClose={() => closeToast(key)} />
                ))}
            </div>
    )
}

export default ToastContainer;
