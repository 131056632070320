const getDateParts = (date: Date): [days: number, hours: number, minutes: number, seconds: number] => {
    if (typeof date === "undefined" || date === null) return null;

    const time = date.getTime();
    const days = time / (24 * 60 * 60 * 1000);
    const hours = time / (60 * 60 * 1000) % 24;
    const minutes = time / (60 * 1000) % 60;
    const seconds = time / 1000 % 60;
    const parts: [days: number, hours: number, minutes: number, seconds: number] = [ days, hours, minutes, seconds, ];

    return parts;
}

/** A utility which contains methods for working with dates. */
export const dateUtility = {

    /** Parses an existing string or date object returning a Date instance (or null if no data was input). */
    parseDate: (d: string | Date): Date => d ? (d instanceof Date ? d : new Date(d)) : null,

    /** Returns a date formatted as 00.00:00:00 where days are omitted if the value is less than a day. */
    formatDate: (date: Date): string => {
        const parts = getDateParts(date);
        const [days, hours, minutes, seconds] = parts;

        if (!parts) {
            return null;
        } else {
            let formatted: string = "";

            if (days >= 1) {
                formatted += `${Math.floor(days)}.`;
            }
            formatted += `${hours < 10 ? "0" : ""}${Math.floor(hours)}:`;
            formatted += `${minutes < 10 ? "0" : ""}${Math.floor(minutes)}:`;
            formatted += `${seconds < 10 ? "0" : ""}${Math.floor(seconds)}`;

            return formatted;
        }
    },

    /** Returns a date formatted as 0.0 Days or 0.0 Hours based on the size of the value. */
    formatDateReallyShort: (date: Date): string => {
        const parts = getDateParts(date);
        const [days, hours, minutes, seconds] = parts;

        if (!parts) {
            return null;
        } else {
            let formatted: string = "";

            if (days >= 1) {
                const hoursDecimal = hours / 24;
                formatted += `${(Math.floor(days) + hoursDecimal).toFixed(2)} Days`;
            } else {
                const minutesDecimal = minutes / 60;
                formatted += `${(Math.floor(days) + minutesDecimal).toFixed(2)} Hours`;
            }

            return formatted.trim();
        }
    },

    /** Returns a date formatted as 0 Days 0 Hours or 0 Hours 0 Minutes based on the size of the value. */
    formatDateShort: (date: Date): string => {
        const parts = getDateParts(date);
        const [days, hours, minutes, seconds] = parts;

        if (!parts) {
            return null;
        } else {
            let formatted: string = "";

            if (days >= 1) {
                formatted += `${Math.floor(days)} Days `;
            }
            formatted += `${Math.floor(hours)} Hours `;
            if (days < 1) {
                formatted += `${Math.floor(minutes)} Minutes`;
            }

            return formatted.trim();
        }
    },

    /** Returns a date formatted as 0 Days, 0 Hours, 0 Minutes, 0 Seconds where values are omitted if the date is not great enough to fulfill it. */
    formatDateLong: (date: Date): string => {
        const parts = getDateParts(date);
        const [days, hours, minutes, seconds] = parts;

        if (!parts) {
            return null;
        } else {
            let formatted: string = "";

            if (days >= 1) {
                formatted += `${Math.floor(days)} Days, `;
            }
            if (hours >= 1 || formatted) {
                formatted += `${Math.floor(hours)} Hours, `;
            }
            if (minutes >= 1 || formatted) {
                formatted += `${Math.floor(minutes)} Minutes, `;
            }
            formatted += `${Math.floor(seconds)} Seconds`;

            return formatted;
        }
    },
}

export default dateUtility;
