import { FunctionComponent, useState } from "react";
import { MedalProgress } from "../../../../models";
import Medal from "./Medal";
import MedalModal from "./MedalModal";
import "./Medals.scss";

export interface MedalsProps {
    medals: MedalProgress[];
}

export const Medals: FunctionComponent<MedalsProps> = (props) => {
    const [details, setDetails] = useState<MedalProgress>();

    return (
        <div className="medals-component">
            <h3 className="h4 text-center mb-4">
                Medals
            </h3>
            <div className="row">
                {props.medals ? props.medals.map((m, idx) => (
                    <div key={idx} className="col-6 col-sm-4 col-md-3 col-xl-2">
                        <Medal
                            medal={m}
                            onClick={() => setDetails(m)}
                        />
                    </div>
                )) : null}
            </div>
            {details ? (
                <MedalModal
                    medal={details}
                    showModal={true}
                    closeModal={() => setDetails(null)}
                />
            ) : null}
        </div>
    );
}

export default Medals;
