import { FunctionComponent, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import config from "../../../config";
import { PageInfo } from "../../../models";
import { analytics } from "../../../utilities";
import "./Tracking.scss";

export interface TrackingProps {
    /** Information about the current page. */
    pageInfo: PageInfo;
};

export const Tracking: FunctionComponent<TrackingProps> = (props) => {
    const location = useLocation();

    // SPA page changes
    useEffect(() => {
        const url = location.pathname && location.pathname.length > 1 ? `${window.location.origin}${location.pathname}` : window.location.origin;

        analytics.trackPageView(null, url);
    }, [location]);

    return (
        <div className="tracking-component">
            {props.pageInfo ? (
                <Helmet
                    defaultTitle={config.defaultPageTitle}
                >
                    { /* Title */ }
                    {props.pageInfo.title ? (
                        <title>
                            {props.pageInfo.title ? `${props.pageInfo.title} | ${config.defaultPageTitle}` : config.defaultPageTitle}
                        </title>
                    ) : null}
                    {props.pageInfo.title ? (
                        <meta
                            property="og:title"
                            content={props.pageInfo.title ? `${props.pageInfo.title} | ${config.defaultPageTitle}` : config.defaultPageTitle}
                        />
                    ) : null}

                    { /* Description */ }
                    {props.pageInfo.description ? (
                        <meta
                            name="description"
                            content={props.pageInfo.description}
                        />
                    ): null}
                    {props.pageInfo.description ? (
                        <meta
                            property="og:description"
                            content={props.pageInfo.description}
                        />
                    ): null}

                    { /* Canonical URL */ }
                    {props.pageInfo.url ? (
                        <link
                            rel="canonical"
                            href={props.pageInfo.url}
                        />
                    ): null}
                    {props.pageInfo.url ? (
                        <meta
                            property="og:url"
                            content={props.pageInfo.url}
                        />
                    ): null}
                </Helmet>
            ) : null}
        </div>
    );
};

export default Tracking;
