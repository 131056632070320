import config from "../config";
import Match from "../models/Match";

/** A service which exposes methods for retrieving Match data. */
export const MatchService = {

    /**
     * Returns a promise of the latest Match data for the specified gamertag.
     * @param {string} gamertag The XBL gamertag of the latest match to lookup.
     */
    getLatestMatch: (gamertag: string): Promise<Match> => fetch(`${config.apiRootUrl}/api/matches/${encodeURIComponent(gamertag)}/latest`)
        .then(resp => resp.status !== 204 ? resp.json() : null)
        .then((match: Match) => match ? new Match(match) : null),
}

export default MatchService;
