import { faUserLock } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { pageUtility } from "../../../utilities";

export interface PlayerNotFoundProps {
    gamertag: string;
}

export const PlayerNotFound: React.FunctionComponent<PlayerNotFoundProps> = (props) => {
    useEffect(() => {
        pageUtility.titlePage("Player Not Found");
    }, []);

    return (
        <div className="player-not-found-component">
            <div className="row">
                <div className="col-12 col-xl-9">
                    <div className="text-center text-sm-start">
                        <FontAwesomeIcon icon={faUserLock} className="h1 align-middle" />
                        <h1 className="d-block d-sm-inline-block ms-sm-3 align-middle">
                            Record Not Found
                        </h1>
                        <p>
                            <small>
                                The specified Spartan was not found.
                                <br />
                                If you've changed your gamertag try using your old one.
                            </small>
                        </p>
                        <blockquote className="blockquote">
                            “Spartans never die. They're just missing in action.”
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayerNotFound;