import React from "react";
import { Rank as RankModel } from "../../../models";

export interface RankProps {
    previousRank: RankModel;
    currentRank: RankModel;
    maxRank: RankModel;
}

export const Rank: React.FunctionComponent<RankProps> = (props) => {
    const totalRemainingXp = props.maxRank.startingXp - props.currentRank.startingXp;
    const totalRemainingPercent = totalRemainingXp / props.maxRank.startingXp;
    const newXp = props.currentRank.startingXp - props.previousRank.startingXp;
    const newPercentage = ((props.currentRank.startingXp - props.previousRank.startingXp) / props.maxRank.startingXp);
    const previousPercentage = newPercentage < 1 ? 1 - totalRemainingPercent - newPercentage : 0;

    return (
        <div className="rank-component">
            <hr />
            <div className="row">
                <div className="col-12 col-md-4">
                    <h3 className="h4 text-center text-md-start">
                        Spartan Rank {props.currentRank.rankNumber}
                    </h3>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col-12">
                            <div className="progress">
                                <div
                                    className="progress-bar bg-primary"
                                    role="progressbar" style={{ width: `${previousPercentage * 100}%`}}
                                    aria-valuenow={props.previousRank.startingXp}
                                    aria-valuemin={0}
                                    aria-valuemax={props.maxRank.startingXp}
                                >

                                </div>
                                <div
                                    className="progress-bar progress-bar-striped bg-success"
                                    role="progressbar" style={{ width: `${newPercentage * 100}%`}}
                                    aria-valuenow={props.currentRank.startingXp}
                                    aria-valuemin={props.previousRank.startingXp}
                                    aria-valuemax={props.maxRank.startingXp}
                                >

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 text-center text-sm-start">
                            <b>Rank XP:</b> { newXp.toLocaleString() } ({ (newPercentage * 100).toFixed(3) }%)
                        </div>
                        <div className="col-12 col-sm-6 text-center text-sm-end">
                            <b>Total XP:</b> { props.currentRank.startingXp.toLocaleString() } ({ ((1 - totalRemainingPercent) * 100).toFixed(3) }%)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rank;
