import { faFacebook, faReddit, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { analytics } from "../../../../utilities";

export interface ShareProps { }

export const Share: FunctionComponent<ShareProps> = (props) => {
    const url = document.location.toString();
    const text = "Follow my progress towards Spartan Rank 152 in Halo 5: Guardians.";
    const windowSize = "width=550,height=420";
    const trackButtonClick = (platform: string) => analytics.trackEvent("share-clicked", { platform, })
    const shareToFacebook = () => {
        trackButtonClick("facebook");
        window.open(`https://facebook.com/sharer.php?u=${encodeURIComponent(url)}&p=${encodeURIComponent(text)}`, "_blank", windowSize);
    };
    const shareToTwitter = () => {
        trackButtonClick("twitter");
        window.open(`https://twitter.com/share?method=share&url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`, "_blank", windowSize);
    };
    const shareToReddit = () => {
        trackButtonClick("reddit");
        window.open(`https://reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`);
    };
    const shareToEmail = () => {
        trackButtonClick("email");
        window.open(`mailto:?subject=<${text}&body=${url}`, "_blank", windowSize);
    };

    return (
        <div className="share-component">
            <strong className="d-block mt-3">
                Share
            </strong>
            <hr className="w-100 mt-1 mb-2" />  
            <div className="d-flex justify-content-around w-50 mx-auto mx-xl-0">
                <button
                    type="button"
                    onClick={() => shareToFacebook()}
                    className="btn btn-sm btn-outline-secondary"
                    title="Share to Facebook"
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </button>
                <button
                    type="button"
                    onClick={() => shareToTwitter()}
                    className="btn btn-sm btn-outline-secondary"
                    title="Share to Twitter"
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </button>
                <button
                    type="button"
                    onClick={() => shareToReddit()}
                    className="btn btn-sm btn-outline-secondary"
                    title="Share to Reddit"
                >
                    <FontAwesomeIcon icon={faReddit} />
                </button>
                <button
                    type="button"
                    onClick={() => shareToEmail()}
                    className="btn btn-sm btn-outline-secondary"
                    title="Share to Email"
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </button>
            </div>
        </div>
    )
}

export default Share;
