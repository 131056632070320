import React, { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import config from "../../../config";
import { PlayerSearch } from "../../../models";
import { ImageService, SearchHistoryService } from "../../../services";
import { analytics } from "../../../utilities";
import "./Navigation.scss";

const sanatizeGamertag = (gamertag: string): string => gamertag?.replace("#", "").toLocaleLowerCase();

export interface NavigationProps { }

/** Primary navigation menu. */
export const Navigation: React.FunctionComponent<NavigationProps> = (props) => {
    const history = useHistory();
    const urlParams = useParams<Partial<{ gamertag: string, }>>();
    const [gamertag, setGamertag] = useState<string>(config.isDevEnvironment ? "Link of Darknes" : "");
    const [subPlayer, setSubPlayer] = useState<PlayerSearch>();
    const [searchHistory, setSearchHistory] = useState<PlayerSearch[]>([]);
    const goHome: FormEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();

        history.push('/');
    };
    const search = (e: FormEvent, gt?: string) => {
        e.preventDefault();

        if (gt || gamertag) {
            analytics.trackEvent("search", {
                search_term: gt || gamertag,
            });

            history.push(`/spartan/${sanatizeGamertag(gt || gamertag)}`);
        }
    };
    const players = searchHistory.filter(p => !p.isLegacy && sanatizeGamertag(p.gamertag) !== urlParams.gamertag);

    useEffect(() => {
        const searchHistory = SearchHistoryService.getAll();
        SearchHistoryService.subscribe(gt => setSubPlayer(gt));
        setSearchHistory(searchHistory || []);
    }, [])

    useEffect(() => {
        if (subPlayer) {
            const history = SearchHistoryService.getAll();
            setSearchHistory(history || []);
        }
    }, [subPlayer])

    return (
        <div className={`navigation-component ${players.length ? "tall" : null}`}>
            <div className="navbar navbar-dark fixed-top bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/" onClick={goHome}>
                        <img src="/images/sr152-alt-118x32.png" style={{ maxHeight: "32px", }} alt="SR152 icon" className="d-inline-block align-top mr-2" />
                    </a>
                    <form className="d-flex" onSubmit={search}>
                        <a href="/ranks"
                            onClick={e => { e.preventDefault(); history.push("/ranks") }}
                            className="btn btn-secondary me-3"
                        >
                            Ranks
                        </a>
                        <input
                            className="form-control me-2"
                            type="search"
                            list="navigation-component-search-history-list"
                            autoComplete="on"
                            placeholder="Gamertag"
                            aria-label="Gamertag"
                            onChange={e => setGamertag(e.target.value)}
                            value={gamertag}
                        />
                        <datalist id="navigation-component-search-history-list">
                            {searchHistory.map((p, idx) => (
                                <option key={idx} value={p.gamertag} />
                            ))}
                        </datalist>
                        <button className="btn btn-primary" type="submit">Search</button>
                    </form>
                </div>
            </div>
            <div className={`navbar navbar-light bg-light recent-players ${!players.length ? "d-none" : null}`}>
                <div className="container-fluid pt-sm-2 ps-4">
                    <div className="row w-100">
                        {players.map((p, idx) => (
                            <div key={idx} className={`col ${idx === 1 ? "d-none d-md-block" : null} ${idx === 2 ? "d-none d-lg-block" : null}  ${idx === 3 ? "d-none d-xl-block" : null} ${idx === 4 ? "d-none d-xxl-block" : null} ${idx > 4 ? "d-none" : null}`}>
                                <button type="button"
                                    className="btn btn-outline-secondary w-100"
                                    onClick={e => search(e, p.gamertag)}
                                >
                                    <img
                                        className="me-2"
                                        src={ImageService.spartanEmblem(p.gamertag)}
                                        title={p.gamertag} alt={`${p.gamertag} emblem`}
                                    />
                                    {p.gamertag}
                                    <small className="d-inline-block ms-2">[{p.serviceTag}]</small>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navigation;
