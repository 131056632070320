import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Rank as RankModel } from "../../models";
import { RankService, ToastService } from "../../services";
import Loading from "../Loading/Loading";
import Rank from "./Rank/Rank";

type RankInfo = {
    previous: RankModel;
    current: RankModel;
    max: RankModel;
}

export interface RanksProps { }

export const Ranks: React.FunctionComponent<RanksProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ranks, setRanks] = useState<RankInfo[]>();
    const [reversedRanks, setReversedRanks] = useState<RankInfo[]>();
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    const getRankInfo = (rank: RankModel, index: number, ranks: RankModel[]): RankInfo => {
        const previous: RankModel = index > 0 ? ranks[index - 1] : rank;
        const max: RankModel = ranks[ranks.length - 1];
        const rankInfo: RankInfo = {
            previous: previous,
            current: rank,
            max: max,
        };

        return rankInfo;
    };

    useEffect(() => {
        Promise.resolve()
            .then(() => RankService.getAll())
            .then(ranks => ranks.map((rank, idx) => getRankInfo(rank, idx, ranks)))
            .then(ranks => {
                setRanks(ranks);
                setReversedRanks(ranks.map(r => r).reverse());
            })
            .catch(error => ToastService.toast("danger", "An error occured retrieving rank information.", "Rank Lookup Error", 10000))
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <div className="rank-component">
            {isLoading ? (
                <Loading message="Retrieving Spartan Ranks" />
            ) : (
                <div className="container mt-3">
                    <h1 className="h2">Spartan Ranks</h1>
                    <hr />
                    <p className="lead">
                        There are 152 Spartan Ranks in Halo: 5 Guardians, each with an increasing experience point (XP) requirement.
                    </p>
                    <p>
                        The below table shows each rank's XP requirement and it's total progression towards Spartan Rank 152.
                    </p>
                    <div className="text-end">
                        <button
                            type="button"
                            className={`btn btn-sm btn-outline-primary ${sortOrder === "asc" ? "active" : null} me-2`}
                            onClick={() => setSortOrder("asc")}
                            title="Sort Ascending"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleDown}
                                size="sm"
                            />
                            <span className="d-none d-md-inline ms-1">
                                Ascending
                            </span>
                        </button>
                        <button
                            type="button"
                            className={`btn btn-sm btn-outline-primary ${sortOrder === "desc" ? "active" : null}`}
                            onClick={() => setSortOrder("desc")}
                            title="Sort Descending"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleUp}
                                size="sm"
                            />
                            <span className="d-none d-md-inline ms-1">
                                Descending
                            </span>
                        </button>
                    </div>
                    <div className="mb-4">
                        {(sortOrder !== "desc" ? ranks : reversedRanks).map((rank, idx) => (
                            <Rank
                                key={`${idx}-${sortOrder}`}
                                previousRank={rank.previous}
                                currentRank={rank.current}
                                maxRank={rank.max}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Ranks;
