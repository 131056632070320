import { FunctionComponent, useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppInfo, PageInfo } from "../models";
import pageInfo from "../pages";
import About from "./About/About";
import Feedback from "./Feedback/Feedback";
import usePageLayout from "./Layout/Layout";
import Legal from "./Legal/Legal";
import Ranks from "./Ranks/Ranks";
import Spartan from "./Spartan/Spartan";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import Welcome from "./Welcome/Welcome";

type Pages = {
    [key: string]: PageInfo;
}

export interface AppProps { }

export const App: FunctionComponent<AppProps> = (props) => {
    const appInfo: AppInfo = new AppInfo({pageInfo});
    const pages: Pages = useMemo(() => {
        const pages: Pages = {};
        for (let pageInfo of appInfo.pageInfo) {
            pages[pageInfo.path] = pageInfo;
        }
        return pages;
    }, []);
    const defaultPage = pages["/"];
    
    return (
        <div className="page-contents">
            <HelmetProvider>
                <Router>
                    <Switch>
                        <Route path="/about">
                            {usePageLayout(About, {
                                pageInfo: pages["/about"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/feedback">
                            {usePageLayout(Feedback, {
                                pageInfo: pages["/feedback"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/legal">
                            {usePageLayout(Legal, {
                                pageInfo: pages["/legal"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/terms-and-conditions">
                            {usePageLayout(TermsAndConditions, {
                                pageInfo: pages["/terms-and-conditions"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/ranks">
                            {usePageLayout(Ranks, {
                                pageInfo: pages["/ranks"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/spartan/:gamertag">
                            {usePageLayout(Spartan, {
                                pageInfo: pages["/spartan"] || defaultPage,
                            })}
                        </Route>
                        <Route path="/">
                            {usePageLayout(Welcome, {
                                pageInfo: pages["/"] || defaultPage,
                                noPadding: true,
                            })}
                        </Route>
                    </Switch>
                </Router>
            </HelmetProvider>
        </div>
    );
}

export default App;
