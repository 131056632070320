import "./LatestMatch.scss";
import { FunctionComponent } from "react";
import { Match } from "../../../../models";
import { match } from "node:assert";

export interface LatestMatchProps {
    match: Match;
}

export const LatestMatch: FunctionComponent<LatestMatchProps> = (props) => {
    const booster = props.match?.booster;
    const game = props.match?.gameVariant;
    const map = props.match?.mapVariant;

    return props.match ? (
        <div className="latest-match-component">
            <div className="match-container card" style={{ backgroundImage: `url(data:image/png;base64,${map.image})`}}>
                <div className="card-body" style={{ backgroundColor: "rgba(255, 255, 255, 0.65)" }}>
                    <div className="row">
                        <div className="col text-nowrap">
                            <img
                                className="game-thumbnail me-2 d-none d-md-inline-block"
                                src={`data:image/png;base64,${game.image}`}
                                title={game.name}
                            />
                            <div className="d-inline-block">
                                <h3 className="h5 mb-0">
                                    {game.name}
                                </h3>
                                <div className="fs-6 mb-0">
                                    <small>
                                        Map: {map.name}
                                    </small>
                                </div>
                                <div className="d-none d-sm-block">
                                    <small>
                                        <span className="badge me-1" style={{ backgroundColor: props.match.team.toLowerCase(), }}>&nbsp;</span>
                                        { props.match.team } Team
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="col text-end text-sm-center">
                            <div className="fs-5 fw-bold d-none d-sm-block">
                                XP Earned: {props.match.totalXpGained.toLocaleString()}
                                {/* <small className={`fw-normal ${props.match.boosterXp ? "d-block" : "d-none"}`}>
                                    Booster XP: {props.match.boosterXp.toLocaleString()}
                                </small> */}
                            </div>
                            <div className="fs-6 fw-bold d-sm-none">
                                XP Earned: {props.match.totalXpGained.toLocaleString()}
                            </div>
                            <div className="fs-5 d-none d-sm-block">
                                Placement: {props.match.rank.toLocaleString()}
                            </div>
                            <div className="fs-6 d-sm-none">
                                Placement: {props.match.rank.toLocaleString()}
                            </div>
                        </div>
                        {booster ? (
                            <div className="col-2 d-none d-md-block offset-lg-2 text-end">
                                <img
                                    className="img-thumbnail booster-thumbnail me-2 d-none d-md-inline-block"
                                    src={`data:image/png;base64,${booster.image}`}
                                    title={`${booster.rarity} ${booster.name}`}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    ) : (<div />)
}

export default LatestMatch;
