import { FunctionComponent, ReactElement } from "react";
import { Modal } from "react-bootstrap";

export interface FeedbackModalProps {
    show: boolean;
    showFeedback: () => void;
    dismiss: () => void;
}

export const FeedbackModal: FunctionComponent<FeedbackModalProps> = (props): ReactElement => {
    return (
        <Modal show={props.show} onHide={() => props.dismiss()} style={{ marginTop: "100px"}}>
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <h2 className="h3 my-0 py-0 lh-sm">
                        We could use your feedback!
                    </h2>
                    <button
                        type="button"
                        className="btn btn-link text-secondary fs-2 py-0 px-1"
                        title="Close"
                        style={{ lineHeight: "0.75rem", textDecoration: "none", }}
                        onClick={() => props.dismiss()}
                    >
                        &times;
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Your feedback is important to us! It helps us decide what features to add, what adjustments to make, and more!
                </p>
                <p>
                    Please consider taking a few minutes to let us know what we're doing right and where we can improve.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between align-items-start w-100">
                    <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        title="Give Feedback"
                        onClick={() => props.showFeedback()}
                    >
                        Give Feedback
                    </button>
                    <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        title="Close Popup"
                        onClick={() => props.dismiss()}
                    >
                        No Thanks
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default FeedbackModal;
