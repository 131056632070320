/** Information about a player whom was searched. */
export class PlayerSearch {

    /** The player's gamertag. */
    gamertag: string;

    /** The player's service tag. */
    serviceTag: string;

    /** The Base-64 encoded player emblem image. */
    emblemBase64 : string;

    /** A flag which indicates whether the record is a legacy record (from a previous non-compatible build) */
    isLegacy: boolean;

    constructor()
    constructor(playerSearch: Partial<PlayerSearch>)
    constructor(data?: Partial<PlayerSearch>) {
        data = data || {} as any;

        this.gamertag = data.gamertag || null;
        this.serviceTag = data.serviceTag || null;
        this.emblemBase64 = data.emblemBase64 || null;
        this.isLegacy = data.isLegacy || false;
    }
}

export default PlayerSearch;
