import { FunctionComponent, ReactElement, useState } from "react";

let fieldNum: number = 1;

export interface FeedbackCheckboxProps {
    fieldName: string;
    label: string;
    isLike?: boolean;
    setValue: (value: boolean) => void;
    setComment?: (comment: string) => void;
}

export const FeedbackCheckbox: FunctionComponent<FeedbackCheckboxProps> = (props): ReactElement => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const setCheckedState = (val: boolean) => {
        setIsChecked(val);
        props.setValue(val);
    };
    const setCommentState = (comment: string) => {
        setComment(comment);
        props.setComment(comment);
    };
    const commentText = props.isLike
        ? "Let us know if you have any suggestions for this feature:"
        : "Let us know what changes or alternatives you'd like to see in this feature:";
    fieldNum += 1;

    return (
        <div className="feedback-checkbox-component mb-3">
            <input
                type="checkbox"
                id={`${props.fieldName}-${fieldNum}`}
                className="form-check-input"
                checked={isChecked || false}
                onChange={e => setCheckedState(e.target.checked)}
            />
            <label htmlFor={`${props.fieldName}-${fieldNum}`} className="form-label ms-2" style={{ display: "inline", }}>
                {props.label}
            </label>
            <div className={!isChecked || !props.setComment ? "d-none" : null}>
                <label htmlFor={`${props.fieldName}-comment-${fieldNum}`} className="form-label">
                    { commentText }
                </label>
                <input
                    type="text"
                    id={`${props.fieldName}-comment-${fieldNum}`}
                    className="form-control"
                    maxLength={2000}
                    value={comment || ""}
                    onChange={e => setCommentState(e.target.value)}
                />
            </div>
        </div>
    )
}

export default FeedbackCheckbox;
