import React, { useMemo } from "react";
import { Rank } from "../../../../models";

export interface ProgressBarProps {
    completionPercentage: number;
    currentXp: number;
    ranks: Rank[];
}

// TODO: need to expand or modal an XP table showing each level's XP and the progress towards it (and how insignificant it is to 152)

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props) => {
    const minXp = 0;
    const maxXp = props.ranks ? props.ranks[props.ranks.length - 1].startingXp : 0;
    const percentage = Math.min(1, props?.currentXp / maxXp) || 0;

    const [currentLevel, nextLevel, maxLevel] = useMemo((): [currentRank: Rank, nextRank: Rank, maxLevel: Rank] => {
        const currentXp = props?.currentXp || 0;
        const maxRank: Rank = props.ranks[props.ranks.length - 1];
        let currentRank: Rank;
        let nextRank: Rank;

        if (currentXp <= 0) {
            currentRank = props.ranks[0];
            nextRank = props.ranks[1];
        } else if (currentXp >= maxXp) {
            currentRank = nextRank = maxRank;
        } else {
            const remainingRanks = props.ranks.filter(r => r.startingXp > currentXp);
            nextRank = remainingRanks[0];
            currentRank = props.ranks[props.ranks.indexOf(nextRank) - 1];
        }

        return [ currentRank, nextRank, maxRank, ];
    }, [props.ranks, props.currentXp]);

    const percentageValue: React.ReactElement = (
        <b>
            { (percentage * 100).toFixed(2) }%
            <span className="d-none d-md-inline-block ms-2">
                { props.currentXp.toLocaleString() } XP / { maxLevel.startingXp.toLocaleString() } XP
            </span>
        </b>
    );

    return (
        <div className="progress-bar-component px-3 px-sm-0">
            <div className="progress border" style={{ height: "25px", }}>
                <div
                    className="progress-bar bg-success text-white"
                    role="progressbar"
                    style={{ width: `${percentage * 100}%`, }}
                    aria-valuenow={props?.currentXp}
                    aria-valuemin={minXp}
                    aria-valuemax={maxXp}
                >
                { percentage >= .5 ? percentageValue : null }
                </div>
                <div
                    className="progress-bar bg-light text-dark"
                    role="progressBar"
                    style={{ width: `${(1 - percentage) * 100}%`, }}
                    aria-hidden="true"
                >
                    { percentage < .5 ? percentageValue : null }
                </div>
            </div>
            <div className="row">
                <div className="col-sm text-center text-sm-start">
                    <b>Current Rank:</b> { currentLevel.rankNumber } ({ currentLevel.startingXp.toLocaleString() } XP)
                </div>
                <div className="col-sm d-block d-sm-none d-md-block text-center">
                    <b>Next Rank:</b> { nextLevel.rankNumber } ({ nextLevel.startingXp.toLocaleString() } XP)
                </div>
                <div className="col-sm text-center text-sm-end">
                    <b>Max Rank:</b> { maxLevel.rankNumber } ({ maxLevel.startingXp.toLocaleString() } XP)
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;
