import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faAlignLeft, faAlignSlash, faBahai, faBomb, faCarCrash, faChartLineDown, faClock, faComet, faCrosshairs, faGamepadAlt, faHandRock, faHelmetBattle, faKnifeKitchen, faTasks, faTombstoneAlt, faTrophyAlt, faUserAlien
} from "@fortawesome/pro-duotone-svg-icons";
import React, { useEffect, useState } from "react";
import { MedalProgress, ServiceRecord, Spartan } from "../../../../models";
import { analytics, dateUtility } from "../../../../utilities";
import Medals from "../Medals/Medals";
import PlayerStat from "./PlayerStat/PlayerStat";
import "./PlayerStats.scss";
import StatDetailsModal from "./StatDetailsModal/StatDetailsModal";

type StatType = "all" | "arena" | "warzone";
type StatValue = number | Date;
type StatInfo = {
    statName: string;
    icon: IconDefinition;
    isLargeStat?: boolean;
    currentLabel?: string;
    currentValue: StatValue;
    remainingLabel?: string;
    remainingValue: StatValue;
    totalLabel?: string;
    totalValue: StatValue;
}
type StatInfos = {
    all: StatInfo[];
    arena: StatInfo[];
    warzone: StatInfo[];
}
type MedalInfos = {
    all: MedalProgress[];
    arena: MedalProgress[];
    warzone: MedalProgress[];
}

const shortValueFormatter = (value: StatValue): string => {
    let formatted: string = "";

    if (typeof value === "number") {
        formatted = value.toLocaleString();
    } else if (value instanceof Date) {
        formatted = dateUtility.formatDateShort(value);
    } else if (typeof value === "string") {
        formatted = value;
    }

    return formatted;
}

const longValueFormatter = (value: StatValue): string => {
    let formatted: string = "";

    if (typeof value === "number") {
        formatted = value.toLocaleString();
    } else if (value instanceof Date) {
        formatted = dateUtility.formatDateLong(value);
    } else if (typeof value === "string") {
        formatted = value;
    }

    return formatted;
}

const getStatInfoFromServiceRecord = (serviceRecord: ServiceRecord, isArena: boolean = false): StatInfo[] => {
    const stats: Array<StatInfo & { arenaExclude?: boolean; arenaInclude?: boolean; }> = [
        {
            statName: "Time Played",
            icon: faClock,
            isLargeStat: true,
            currentLabel: "Current Time",
            currentValue: serviceRecord?.currentTimePlayed || new Date(0),
            remainingLabel: "Est. Time Remaining",
            remainingValue: serviceRecord?.maxRankTimeRemaining || new Date(0),
            totalLabel: "Est. Total Time",
            totalValue: serviceRecord ? new Date(serviceRecord?.currentTimePlayed.getTime() + serviceRecord?.maxRankTimeRemaining.getTime()) : new Date(0),
        },
        {
            statName: "Games Played",
            icon: faGamepadAlt,
            currentLabel: "Current Games Played",
            currentValue: serviceRecord?.currentGamesPlayed || 0,
            remainingLabel: "Est. Games Remaining",
            remainingValue: serviceRecord?.maxRankGamesRemaining || 0,
            totalLabel: "Est. Total Games",
            totalValue: (serviceRecord?.currentGamesPlayed + serviceRecord?.maxRankGamesRemaining) || 0,
        },
        {
            statName: "Games Won",
            icon: faTrophyAlt,
            currentLabel: "Current Wins",
            currentValue: serviceRecord?.currentGamesWon || 0,
            remainingLabel: "Est. Wins Remaining",
            remainingValue: serviceRecord?.maxRankGamesWonRemaining || 0,
            totalLabel: "Est. Total Wins",
            totalValue: (serviceRecord?.currentGamesWon + serviceRecord?.maxRankGamesWonRemaining) || 0,
        },
        {
            statName: "Games Lost",
            icon: faChartLineDown,
            currentLabel: "Current Losses",
            currentValue: serviceRecord?.currentGamesLost || 0,
            remainingLabel: "Est. Losses Remaining",
            remainingValue: serviceRecord?.maxRankGamesLostRemaining || 0,
            totalLabel: "Est. Total Losses",
            totalValue: (serviceRecord?.currentGamesLost + serviceRecord?.maxRankGamesLostRemaining) || 0,
        },
        {
            statName: "Kills",
            icon: faCrosshairs,
            currentLabel: "Current Kills",
            currentValue: serviceRecord?.currentSpartanKills || 0,
            remainingLabel: "Est. Kills Remaining",
            remainingValue: serviceRecord?.maxRankSpartanKillsRemaining || 0,
            totalLabel: "Est. Total Kills",
            totalValue: (serviceRecord?.currentSpartanKills + serviceRecord?.maxRankSpartanKillsRemaining) || 0,
            arenaExclude: true,
        },
        {
            statName: "Total Kills",
            icon: faCrosshairs,
            currentLabel: "Current Kills",
            currentValue: serviceRecord?.currentTotalKills || 0,
            remainingLabel: "Est. Kills Remaining",
            remainingValue: serviceRecord?.maxRankTotalKillsRemaining || 0,
            totalLabel: "Est. Total Kills",
            totalValue: (serviceRecord?.currentTotalKills + serviceRecord?.maxRankTotalKillsRemaining) || 0,
            arenaInclude: true,
        },
        {
            statName: "Spartan Kills",
            icon: faHelmetBattle,
            currentLabel: "Current Spartan Kills",
            currentValue: serviceRecord?.currentSpartanKills || 0,
            remainingLabel: "Est. Spartan Kills Remaining",
            remainingValue: serviceRecord?.maxRankSpartanKillsRemaining || 0,
            totalLabel: "Est. Spartan Total Kills",
            totalValue: (serviceRecord?.currentSpartanKills + serviceRecord?.maxRankSpartanKillsRemaining) || 0,
            arenaInclude: true,
        },
        {
            statName: "NPC Kills",
            icon: faUserAlien,
            currentLabel: "Current NPC Kills",
            currentValue: serviceRecord?.currentPveKills || 0,
            remainingLabel: "Est. NPC Kills Remaining",
            remainingValue: serviceRecord?.maxRankPveKillsRemaining || 0,
            totalLabel: "Est. NPC Total Kills",
            totalValue: (serviceRecord?.currentPveKills + serviceRecord?.maxRankPveKillsRemaining) || 0,
            arenaInclude: true,
        },
        {
            statName: "Deaths",
            icon: faTombstoneAlt,
            currentLabel: "Current Deaths",
            currentValue: serviceRecord?.currentDeaths || 0,
            remainingLabel: "Est. Deaths Remaining",
            remainingValue: serviceRecord?.maxRankDeathsRemaining || 0,
            totalLabel: "Est. Total Deaths",
            totalValue: (serviceRecord?.currentDeaths + serviceRecord?.maxRankDeathsRemaining) || 0,
        },
        {
            statName: "Shots Fired",
            icon: faAlignLeft,
            currentLabel: "Current Shots Fired",
            currentValue: serviceRecord?.currentShotsFired || 0,
            remainingLabel: "Est. Remaining Shots",
            remainingValue: serviceRecord?.maxRankShotsFiredRemaining || 0,
            totalLabel: "Est. Total Shots",
            totalValue: (serviceRecord?.currentShotsFired + serviceRecord?.maxRankShotsFiredRemaining) || 0,
        },
        {
            statName: "Shots Hit",
            icon: faTasks,
            currentLabel: "Current Hits",
            currentValue: serviceRecord?.currentShotsLanded || 0,
            remainingLabel: "Est. Remaining Hits",
            remainingValue: serviceRecord?.maxRankShotsLandedRemaining || 0,
            totalLabel: "Est. Total Hits",
            totalValue: (serviceRecord?.currentShotsLanded + serviceRecord?.maxRankShotsLandedRemaining) || 0,
        },
        {
            statName: "Shots Missed",
            icon: faAlignSlash,
            currentLabel: "Current Misses",
            currentValue: (serviceRecord?.currentShotsFired - serviceRecord?.currentShotsLanded) || 0,
            remainingLabel: "Est. Remaining Misses",
            remainingValue: (serviceRecord?.maxRankShotsFiredRemaining - serviceRecord?.maxRankShotsLandedRemaining) || 0,
            totalLabel: "Est. Total Misses",
            totalValue: ((serviceRecord?.currentShotsFired - serviceRecord?.currentShotsLanded) + (serviceRecord?.maxRankShotsFiredRemaining - serviceRecord?.maxRankShotsLandedRemaining)) || 0,
        },
        {
            statName: "Vehicles Destroyed",
            icon: faCarCrash,
            currentLabel: "Current Vehicles Destroyed",
            currentValue: serviceRecord?.currentVehiclesDestroyed || 0,
            remainingLabel: "Est. Remaining Vehicle Destroys",
            remainingValue: serviceRecord?.maxRankVehiclesDestroyedRemaining || 0,
            totalLabel: "Est. Total Vehicle Destroys",
            totalValue: (serviceRecord?.currentVehiclesDestroyed + serviceRecord?.maxRankVehiclesDestroyedRemaining) || 0,
        },
        {
            statName: "Grenade Kills",
            icon: faBomb,
            currentLabel: "Current Grenade Kills",
            currentValue: serviceRecord?.currentGrenadeKills || 0,
            remainingLabel: "Est. Remaining Grenade Kills",
            remainingValue: serviceRecord?.maxRankGrenadeKillsRemaining || 0,
            totalLabel: "Est. Total Grenade Kills",
            totalValue: (serviceRecord?.currentGrenadeKills + serviceRecord?.maxRankGrenadeKillsRemaining) || 0,
        },
        {
            statName: "Melee Kills",
            icon: faHandRock,
            currentLabel: "Current Melee Kills",
            currentValue: serviceRecord?.currentMeleeKills || 0,
            remainingLabel: "Est. Remaining Melee Kills",
            remainingValue: serviceRecord?.maxRankMeleeKillsRemaining || 0,
            totalLabel: "Est. Total Melee Kills",
            totalValue: (serviceRecord?.currentMeleeKills + serviceRecord?.maxRankMeleeKillsRemaining) || 0,
        },
        {
            statName: "Assassinations",
            icon: faKnifeKitchen,
            currentLabel: "Current Assassinations",
            currentValue: serviceRecord?.currentAssassinations || 0,
            remainingLabel: "Est. Remaining Assassinations",
            remainingValue: serviceRecord?.maxRankAssassinationsRemaining || 0,
            totalLabel: "Est. Total Assassinations",
            totalValue: (serviceRecord?.currentAssassinations + serviceRecord?.maxRankAssassinationsRemaining) || 0,
        },
        {
            statName: "Shoulder Bash Kills",
            icon: faBahai,
            currentLabel: "Current Shoulder Bash Kills",
            currentValue: serviceRecord?.currentShoulderBashKills || 0,
            remainingLabel: "Est. Remaining Shoulder Bash Kills",
            remainingValue: serviceRecord?.maxRankShoulderBashKillsRemaining || 0,
            totalLabel: "Est. Total Shoulder Bash Kills",
            totalValue: (serviceRecord?.currentShoulderBashKills + serviceRecord?.maxRankShoulderBashKillsRemaining) || 0,
        },
        {
            statName: "Ground Pound Kills",
            icon: faComet,
            currentLabel: "Current Ground Pound Kills",
            currentValue: serviceRecord?.currentGroundPoundKills || 0,
            remainingLabel: "Est. Remaining Ground Pound Kills",
            remainingValue: serviceRecord?.maxRankGroundPoundKillsRemaining || 0,
            totalLabel: "Est. Total Ground Pound Kills",
            totalValue: (serviceRecord?.currentGroundPoundKills + serviceRecord?.maxRankGroundPoundKillsRemaining) || 0,
        },
    ];
    const filteredStats = stats.filter(s => typeof (s.arenaExclude || s.arenaInclude) === "undefined" || (s.arenaExclude == isArena || s.arenaInclude == !isArena));

    return filteredStats;
}

export interface PlayerStatsProps {
    spartan: Spartan;
}

export const PlayerStats: React.FunctionComponent<PlayerStatsProps> = (props) => {
    const [statType, setStatType] = useState<StatType>("all");
    const [details, setDetails] = useState<StatInfo>();
    const selectStatType = (type: StatType) => {
        analytics.trackEvent("view_item_list", {
            items: [{
                name: type,
            }],
        });

        setStatType(type);
    }
    const viewDetails = (stat: StatInfo) => {
        if (stat) {
            analytics.trackEvent("view_item", {
                items: [{
                    name: stat.statName,
                    statType: statType,
                }],
            });
        }

        setDetails(stat);
    };
    const stats: StatInfos = {
        all: getStatInfoFromServiceRecord(props.spartan?.allTime),
        arena: getStatInfoFromServiceRecord(props?.spartan?.arena, true),
        warzone: getStatInfoFromServiceRecord(props?.spartan?.warzone),
    };
    const medals: MedalInfos = {
        all: props.spartan?.allTime?.medalProgress || [],
        arena: props.spartan?.arena?.medalProgress || [],
        warzone: props.spartan?.warzone?.medalProgress || [],
    };

    useEffect(() => selectStatType("all"), []);

    return (
        <div className="player-stats-component">
            <ul className="nav nav-tabs nav-justified mb-2">
                <li className="nav-item">
                    <a
                        className={`nav-link ${statType === "all" ? "active" : ""}`}
                        aria-current={statType === "all" ? "page" : "false"}
                        onClick={() => selectStatType("all")}
                    >
                        All <span className="d-none d-sm-inline">Stats</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${statType === "arena" ? "active" : ""}`}
                        aria-current={statType === "arena" ? "page" : "false"}
                        onClick={() => selectStatType("arena")}
                    >
                        Arena <span className="d-none d-sm-inline">Stats</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${statType === "warzone" ? "active" : ""}`}
                        aria-current={statType === "warzone" ? "page" : "false"}
                        onClick={() => selectStatType("warzone")}
                    >
                        Warzone <span className="d-none d-sm-inline">Stats</span>
                    </a>
                </li>
            </ul>
            <div className="px-1">
                {stats[statType].map((stat, idx) => (
                    <div key={idx} className="player-stat">
                        <PlayerStat
                            statName={stat.statName}
                            icon={stat.icon}
                            isLargeStat={stat.isLargeStat}
                            currentLabel={stat.currentLabel}
                            currentValue={shortValueFormatter(stat.currentValue)}
                            remainingLabel={stat.remainingLabel}
                            remainingValue={shortValueFormatter(stat.remainingValue)}
                            totalLabel={stat.totalLabel}
                            totalValue={shortValueFormatter(stat.totalValue)}
                            showDetails={() => viewDetails(stats[statType][idx])}
                        />
                    </div>
                ))}
            </div>
            <div className="">
                <Medals
                    medals={medals[statType]}
                />
            </div>
            {details ? (
                <StatDetailsModal
                    statName={details.statName}
                    icon={details.icon}
                    currentLabel={details.currentLabel}
                    currentValue={longValueFormatter(details.currentValue)}
                    remainingLabel={details.remainingLabel}
                    remainingValue={longValueFormatter(details.remainingValue)}
                    totalLabel={details.totalLabel}
                    totalValue={longValueFormatter(details.totalValue)}
                    showModal={true}
                    closeModal={() => setDetails(null)}
                />
            ) : (<div />)}
        </div>
    );
}

export default PlayerStats;
