import amplitude, { AmplitudeClient } from "amplitude-js";
import config from "../config";

const amp: AmplitudeClient = amplitude.getInstance();
amp.init(config.amplitudeClientId);

export type AnalyticEvent = "page_view" | "search" | "view_item" | "view_item_list" | "select_item" | "share-clicked";
export type AnalyticData = { };

/** A utility for working with analytical data. */
export const analytics = {

    /** Tracks a page view. */
    trackPageView: (pageTitle?: string, pageURL?: string): void => {
        const data = {
            page_title: pageTitle || document.title || config.defaultPageTitle,
            page_url: pageURL || document.location.toString(),
        };

        analytics.trackEvent("page_view", data);
    },

    /** Tracks an event. */
    trackEvent: (event: AnalyticEvent, data: AnalyticData): void => {
        if (event && data && !config.isDevEnvironment) {
            if (event === "page_view") {
                gtag("config", config.googleAnalyticsId, data);
            } else {
                gtag("event", event, data);
            }

            amp.logEvent(event, data);
        }
    },
};

export default analytics;
