import { PageInfo } from "./models";

export const pages: PageInfo[] = [
    {
        name: "Home",
        url: "https://sr152.app",
        path: "/",
        title: "",
        description: "Halo 5: Guardians Spartan Rank 152 (SR 152) Progress Tracker & XP Tracker."
    },
    {
        name: "Spartan",
        url: "https://sr152.app/spartan",
        path: "/spartan",
        title: null,
        description: "Player progression and statistics towards Spartan Rank 152."
    },
    {
        name: "Ranks",
        url: "https://sr152.app/ranks",
        path: "/ranks",
        title: "Ranks",
        description: "All 152 Spartan Ranks in Halo 5: Guardians along with their XP requirement."
    },
    {
        name: "Feedback",
        url: "https://sr152.app/feedback",
        path: "/feedback",
        title: "Feedback",
        description: "We'd love to hear what you think about SR152. Let us know what improvements you'd like to see or what we can improve."
    },
    {
        name: "About",
        url: "https://sr152.app/about",
        path: "/about",
        title: "About",
        description: "SR152 is a small app designed to allow users to track their progress towards Spartan Rank 152 in Halo: 5 Guardians."
    },
    {
        name: "Legal",
        url: "https://sr152.app/legal",
        path: "/legal",
        title: "Legal Disclaimers",
        description: "The legal stuff we have to provide."
    },
    {
        name: "Terms & Conditions",
        url: "https://sr152.app/terms-and-conditions",
        path: "/terms-and-conditions",
        title: "Terms & Conditions",
        description: "Terms and conditions governing the use of the application."
    },
];

export default pages;
