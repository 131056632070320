import React, { useEffect } from "react";
import config from "../../../config";
import { Match, Rank, Spartan } from "../../../models";
import { SearchHistoryService } from "../../../services";
import { dateUtility, pageUtility } from "../../../utilities";
import LatestMatch from "./LatestMatch/LatestMatch";
import PlayerName from "./PlayerName/PlayerName";
import PlayerStats from "./PlayerStats/PlayerStats";
import ProgressBar from "./ProgressBar/ProgressBar";
import Share from "./Share/Share";
import SpartanImage from "./SpartanImage/SpartanImage";

export interface PlayerProps {
    ranks: Rank[];
    spartan: Spartan;
    latestMatch: Match;
}

export const Player: React.FunctionComponent<PlayerProps> = (props) => {
    useEffect(() => {
        pageUtility.titlePage(`Spartan ${props.spartan?.gamertag}`);
        SearchHistoryService.publish(props.spartan?.gamertag, props.spartan?.serviceTag);
    }, []);

    return (
        <div className="player-component">
            <div className="row">
                <div className="col-12 col-xl-9">
                    <div>
                        <PlayerName
                            gamertag={props.spartan?.gamertag}
                            serviceTag={props.spartan?.serviceTag}
                        />
                    </div>
                    <div className="my-3">
                        <ProgressBar
                            ranks={props.ranks}
                            completionPercentage={props.spartan?.maxRankOverallCompletion}
                            currentXp={props.spartan?.currentXp}
                        />
                    </div>
                    <hr className="mt-3" />
                    <LatestMatch match={props.latestMatch} />
                    <div className="mt-3">
                        <PlayerStats
                            spartan={props.spartan}
                        />
                    </div>
                </div>
                <div className="col-12 col-xl-3">
                    <div className="d-none d-xl-block">
                        <SpartanImage
                            gamertag={props.spartan?.gamertag}
                        />
                    </div>
                    <div className="text-center text-xl-start p-3">
                        <Share />
                    </div>
                    <div className="d-none d-xl-block px-3 mt-3">
                        <strong className="d-block">
                            Quick Stats
                        </strong>
                        <hr className="my-2" />
                        <button
                            type="button"
                            title="View Medals"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => window.document.getElementsByClassName("medals-component")[0].scrollIntoView() }
                        >
                            View Medals
                        </button>
                    </div>
                </div>
            </div>
            <div className="disclaimer">
                <div className="mt-3 small text-center text-sm-start">
                    <span className="me-0 me-sm-5">
                        Last Updated: {props.spartan.lastModified.toLocaleString()}
                    </span>
                    <span className="d-block d-sm-inline-block">
                        Stats are updated every {dateUtility.formatDateLong(config.cacheTime)}
                    </span>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-xl-9">
                        <p className="small">
                            *Stats are linear estimations based on previous match data; they represent no guarantee and may be impacted by changes to the players preformance, the current player population, and use of XP boosters.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Player;