import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHelmetBattle } from "@fortawesome/pro-duotone-svg-icons";

export interface LoadingProps {
    message?: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = (props) => {
    const [time, setTime] = useState<number>(0);
    useEffect(() => {
        setTimeout(() => setTime(time + 1), 500)
    }, [time])

    return (
        <div className="w-100">
            <div className="loading-component text-center mt-md-5" style={{ margin: "auto", }}>
                <FontAwesomeIcon icon={faHelmetBattle} className="h2 d-block d-sm-inline-block mx-auto mx-sm-0 align-middle" />
                <h2 className="ms-2 d-inline-block align-middle">
                    { props.message || "Loading" }{ Array((time % 3) + 1).fill(null).map((ele, idx) => (<span key={idx}>.</span>)) }
                </h2>
            </div>
        </div>
    );
}

export default Loading;
