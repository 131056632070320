import { dateUtility } from "../utilities";
import ServiceRecord from "./ServiceRecord";

/** Information representing a Spartan (player). */
export class Spartan {
    gamertag: string;
    lastModified: Date;
    serviceTag: string;
    companyId: string;
    companyName: string;
    currentRank: number;
    currentXp: number;
    maxRank: number;
    maxRankXp: number;
    maxRankOverallCompletion: number;
    allTime: ServiceRecord;
    arena: ServiceRecord;
    warzone: ServiceRecord;

    constructor()
    constructor(spartan: Partial<Spartan>)
    constructor(data?: Partial<Spartan>) {
        data = data || {};

        this.gamertag = data.gamertag || null;
        this.lastModified = dateUtility.parseDate(data.lastModified);
        this.serviceTag = data.serviceTag || null;
        this.companyId = data.companyId || null;
        this.companyName = data.companyName || null;
        this.currentRank = data.currentRank || 0;
        this.currentXp = data.currentXp || 0;
        this.maxRank = data.maxRank || 0;
        this.maxRankXp = data.maxRankXp || 0;
        this.maxRankOverallCompletion = data.maxRankOverallCompletion || 0;
        this.allTime = data.allTime ? new ServiceRecord(data.allTime) : null;
        this.arena = data.arena ? new ServiceRecord(data.arena) : null;
        this.warzone = data.warzone ? new ServiceRecord(data.warzone) : null;
    }
}

export default Spartan;
