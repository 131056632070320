/** A utility class for working with storages. */
export const storageUtility = {

    /** Gets an object from local storage, returning null if it's not found. */
    getFromLocalStorage: <T>(key: string): T => {
        const json = localStorage.getItem(key);
        const result = json ? JSON.parse(json) : null;

        return result;
    },

    /** Saves an object to local storage. */
    saveToLocalStorage: <T>(key: string, value: T): void => {
        const json = JSON.stringify(value);

        localStorage.setItem(key, json);
    },
};

export default storageUtility;
