import { FunctionComponent } from "react";

export interface LegalProps { }

export const Legal: FunctionComponent<LegalProps> = (props) => {
    return (
        <div className="container mt-3">
            <h1 className="h2">Legal Notices</h1>

            <h2 className="h3 mt-5">
                SR152</h2>
            <hr />
            <p>
                The application and SR152 logo are Copyright &copy; Lod Softworks LLC, All Rights Reserved.
            </p>
            <p>
                This application is provided as-is and may be modified, suspended, or discontinued at any time without notice or liability.
            </p>
            <p>
                Use of the application is subject to Lod Softwork LLC's&nbsp;
                <a
                    title="Privacy Policy"
                    href="https://www.lodsoftworks.com/privacy"
                    target="_blank"
                >
                    Privacy Policy
                </a>.
            </p>

            <h2 className="h3 mt-5">Halo</h2>
            <hr />
            <p>
                This application is offered by Lod Softworks LLC, which is solely responsible for its content.
                It is not sponsored or endorsed by Microsoft.
                This application uses the Halo® Game Data API.
            </p>
            <p>
                Halo © Microsoft Corporation. All rights reserved. Microsoft, Halo, and the Halo Logo are trademarks of the Microsoft group of companies.
            </p>
            <p>
                Image assets excluding the SR152 logo and Lod Softworks logo are owned and copyrighted by Microsoft Corporation.
            </p>

            <h2 className="h3 mt-5">Iconography</h2>
            <hr />
            <p>
                Icons are provided under license by <a href="https://www.fontawesome.com" title="Font Awesome" target="_blank">Font Awesome</a>.
            </p>
        </div>
    );
}

export default Legal;
