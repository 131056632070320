import dateUtility from "../utilities/dateUtility";
import Booster from "./Booster";
import GameVariant from "./GameVariant";
import MapVariant from "./MapVariant";

export class Match {
    gamertag: string;
    matchDate: Date;
    team: string;
    rank: number;
    won: boolean;
    preGameSpartanRank: number;
    postGameSpartanRank: number;
    preGameTotalXp: number;
    postGameTotalXp: number;
    totalXpGained: number;
    boosterXp: number;
    booster: Booster;
    gameVariant: GameVariant;
    mapVariant: MapVariant;

    constructor()
    constructor(match: Partial<Match>)
    constructor(data?: Partial<Match>) {
        data = data || {};

        this.gamertag = data.gamertag || null;
        this.matchDate = dateUtility.parseDate(data.matchDate) || null;
        this.team = data.team || null;
        this.rank = data.rank || 0;
        this.won = data.won || false;
        this.preGameSpartanRank = data.preGameSpartanRank || 0;
        this.postGameSpartanRank = data.postGameSpartanRank || 0;
        this.preGameTotalXp = data.preGameTotalXp || 0;
        this.postGameTotalXp = data.postGameTotalXp || 0;
        this.totalXpGained = data.totalXpGained || 0;
        this.boosterXp = data.boosterXp || 0;
        this.booster = data.booster ? new Booster(data.booster) : null;
        this.gameVariant = data.gameVariant ? new GameVariant(data.gameVariant) : null;
        this.mapVariant = data.mapVariant ? new MapVariant(data.mapVariant) : null;
    }
}

export default Match;
