import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserHistory } from "history";
import { StrictMode } from "react";
import { hydrate, render } from "react-dom";
import App from "./components/App";
import "./index.scss";
import "./polyfill";
import reportWebVitals from "./reportWebVitals";

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: "InstrumentationKey=a8076460-df00-4f4f-86f6-defa95c461ff;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/;ApplicationId=4e0d89eb-2d71-4940-b8df-78a1caae74fa",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: browserHistory,
      },
    },
  },
});

const rootElement = document.getElementById("root");
const renderOp = rootElement.hasChildNodes() ? hydrate : render;
renderOp(
  <StrictMode>
    <App />
  </StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
appInsights.loadAppInsights();
appInsights.trackPageView();
