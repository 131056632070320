const isDevEnvironment: boolean = process.env.NODE_ENV !== "production";

/** Constant configuration values. */
export const config = {

    /** The site's Amplitude client identifier. */
    amplitudeClientId: "c8d311a9a65da4664205700f1884fc6b",

    /** The root URL of the API. */
    apiRootUrl: isDevEnvironment ? "https://localhost:44346" : "",

    /** The default cache time for player data. */
    cacheTime: new Date(5 * 60 * 1000),

    /** The default page title. */
    defaultPageTitle: "SR152 - Halo 5: Guardians Progress Tracker",

    /** A flag indicating whether the build is running in a development environment. */
    isDevEnvironment: isDevEnvironment,

    /** The site's Google Analytics identifier. */
    googleAnalyticsId: "G-LCC6810SJG",
}

export default config;
