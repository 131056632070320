export class Booster {
    name: string;
    description: string | null;
    rarity: string;
    image: string;

    constructor()
    constructor(booster: Partial<Booster>)
    constructor(data?: Partial<Booster>) {
        data = data || {};

        this.name = data.name || null;
        this.description = data.description || null;
        this.rarity = data.rarity || null;
        this.image = data.image || null;
    }
}

export default Booster;
