type ToastType = "primary" | "secondary" | "success" | "info" | "warning" | "danger";
type ToastInfo = {
    type: ToastType,
    title: string;
    message: string;
    hideAfter: number;
}

interface ToastServiceDef {
    /** Subscribe to new toasts. */
    subscribe: (callback: (toastInfo: ToastInfo) => void) => void;

    /** Toast a new message to the UI. */
    toast: (type: ToastType, message: string, title?: string, hideAfter?: number) => void;
}

const service = {
    subscriptions: Array<(toastInfo: ToastInfo) => void>(0),

    subscribe: (callback: (toastInfo: ToastInfo) => void): void => {
        if (callback) {
            service.subscriptions.push(callback);
        }
    },

    toast: (type: ToastType, message: string, title: string = "", hideAfter: number = null): void => {
        const toast: ToastInfo = {
            type,
            message,
            title,
            hideAfter,
        };

        for (let sub of service.subscriptions) {
            try {
                if (sub) {
                    sub(toast);
                }
            } catch (err) {
                // ignore
            }
        }
    },
};

export const ToastService: ToastServiceDef = service;

export default ToastService;
