import PageInfo from "./PageInfo";

/** Information required to initialize the application. */
export class AppInfo {
    pageInfo: PageInfo[];

    constructor();
    constructor(appInfo: Partial<AppInfo>);
    constructor(data?: Partial<AppInfo>) {
        data = data || {};

        this.pageInfo = (data.pageInfo || []).map(pi => new PageInfo(pi));
    }
}

export default AppInfo;
