import config from "../config";

const baseUrl = `${config.apiRootUrl}/api/images`;

/** Exposes methods for retrieving image data. */
export const ImageService = {
    /** Gets the URL of the identified medal image. */
    medalIcon: (medalId: number | string): string => `${baseUrl}/medals/${medalId}`,

    /** Gets the URL of the identified spartan emblem */
    spartanEmblem: (gamertag: string): string =>  `${baseUrl}/spartans/emblems/${gamertag}`,

    /** Gets the URL of the default spartan image */
    defaultSpartanImage: (): string =>  `${baseUrl}/spartans/image/default`,

    /** Gets the URL of the identified spartan image */
    spartanImage: (gamertag: string): string =>  `${baseUrl}/spartans/images/${gamertag}`,
};

export default ImageService;
