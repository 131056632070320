/** Information regarding a specific Spartan Rank (SR). */
export class Rank {
    rankNumber: number;
    startingXp: number;

    constructor()
    constructor(rank: Partial<Rank>)
    constructor(data?: Partial<Rank>) {
        data = data || {};

        this.rankNumber = data.rankNumber || 0;
        this.startingXp = data.startingXp || 0;
    }
}

export default Rank;
