import React from "react";
import { ImageService } from "../../../../services";
import "./PlayerName.scss";

export interface PlayerNameProps {
    gamertag: string;
    serviceTag: string;
}

export const PlayerName: React.FunctionComponent<PlayerNameProps> = (props) => {
    return (
        <div className="player-name-component">
            <div className="text-center text-sm-start">
                <img
                    src={ImageService.spartanEmblem(props.gamertag)}
                    title={props.gamertag} alt={`${props.gamertag} emblem`}
                />
                <h1 className="d-block d-sm-inline-block ms-sm-3 align-middle">
                    {props.gamertag}
                    {props.serviceTag ? (
                        <small className="d-inline-block ms-2 h4 fw-bold">
                            [{props.serviceTag}]
                        </small>
                    ) : null}
                </h1>   
            </div>
        </div>
    );
}

export default PlayerName;
