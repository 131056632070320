import config from "../config";
import Rank from "../models/Rank";

/** A service which exposes methods for working with Spartan Rank data. */
export const RankService = {

    /** Returns a promise of an array of all Spartan Ranks. */
    getAll: (): Promise<Rank[]> => fetch(`${config.apiRootUrl}/api/ranks`)
        .then(resp => resp.status !== 204 ? resp.json() : null)
        .then((ranks: Rank[]) => ranks && ranks.length ? ranks.map(r => new Rank(r)) : []),
}

export default RankService;
