import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";

export interface StatDetailsModalProps {
    statName: string;
    icon: IconDefinition;
    currentLabel?: string;
    currentValue: string;
    remainingLabel?: string;
    remainingValue: string;
    totalLabel?: string;
    totalValue: string;
    showModal: boolean;
    closeModal: () => void;
}

export const StatDetailsModal: React.FunctionComponent<StatDetailsModalProps> = (props) => {
    return (
        <Modal show={props.showModal} onHide={() => props.closeModal()} style={{ marginTop: "100px"}}>
            <Modal.Header>
                <div className="w-100 text-center">
                    <FontAwesomeIcon icon={props.icon} className="h3 align-middle" title={props.statName} />
                    <h2 className="h3 d-inline-block align-middle ms-3">
                        { props.statName }
                    </h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <b className="d-inline-block me-2">
                        { props.currentLabel || "Current" }:
                    </b>
                    { props.currentValue }
                </p>
                <p>
                    <b className="d-inline-block me-2">
                        { props.remainingLabel || "Remaining" }:
                    </b>
                    { props.remainingValue }
                </p>
                <p>
                    <b className="d-inline-block me-2">
                        { props.totalLabel || "Estimated Total" }:
                    </b>
                    { props.totalValue }
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="ms-auto"
                    onClick={e => props.closeModal()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StatDetailsModal;
