import { FunctionComponent } from "react";
import { MedalProgress } from "../../../../models";
import { ImageService } from "../../../../services";
import "./Medal.scss";

export interface MedalProps { 
    medal: MedalProgress; 
    onClick: (id: number) => void;
}

export const Medal: FunctionComponent<MedalProps> = (props) => {
    return (
        <div className="medal-component text-center mb-3" onClick={() => props?.onClick(props.medal.id)}>
            <img
                src={ImageService.medalIcon(props.medal.id)}
                title={props.medal.name}
            />
            <h4 className="h5 mb-1">
                {props.medal.name}
            </h4>
            <div className="d-sm-none">
                <div>
                    <strong>Current:</strong> {props.medal.currentCount}
                </div>
                <div>
                    <strong>Remaining:</strong> {props.medal.maxLevelRemainingCount}
                </div>
            </div>
            <div className="d-none d-sm-block">
                {props.medal.currentCount}/{props.medal.maxLevelRemainingCount + props.medal.currentCount}
            </div>
        </div>
    );
}

export default Medal;
