import React from "react";
import { ImageService } from "../../../../services";

export interface SpartanImageProps {
    gamertag: string;
}

export const SpartanImage: React.FunctionComponent<SpartanImageProps> = (props) => {
    return (
        <img
            className="img-fluid"
            src={ImageService.spartanImage(props.gamertag)}
            title={props.gamertag}
        />
    );
}

export default SpartanImage;
